import { and, collection, getDocs, or, query, where } from "firebase/firestore"
import { firestore } from "../../../components/firebase/FirebaseConfig"

export const searchCourse = async (text) => {
    if (text.trim() === "") return [];

    try {
        const q = query(collection(firestore, 'courses'),
            or(
                and(
                    where("courseName", ">=", text),
                    where('courseName', '<=', text + '\uf8ff')
                ),
                and(
                    where("courseNameThai", ">=", text),
                    where('courseNameThai', '<=', text + '\uf8ff')
                ),
                and(
                    where("location.district", ">=", text),
                    where('location.district', '<=', text + '\uf8ff')
                ),
                and(
                    where("location.districtEng", ">=", text),
                    where('location.districtEng', '<=', text + '\uf8ff')
                ),
                and(
                    where("location.province", ">=", text),
                    where('location.province', '<=', text + '\uf8ff')
                ),
                and(
                    where("location.provinceEng", ">=", text),
                    where('location.provinceEng', '<=', text + '\uf8ff')
                ),
                and(
                    where("location.subDistrict", ">=", text),
                    where('location.subDistrict', '<=', text + '\uf8ff')
                ),
                and(
                    where("location.subDistrictEng", ">=", text),
                    where('location.subDistrictEng', '<=', text + '\uf8ff')
                ),
            )
        )
        const courseSnap = await getDocs(q);
        const courseList = []
        if (!courseSnap.empty) {
            courseSnap.forEach(doc => {
                courseList.push({ courseId: doc.id, ...doc.data() });
            });
        }
        return courseList;
    } catch (error) {
        console.error('Fail to search course', error);
        return [];
    }
}