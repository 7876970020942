import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from "../module/denyRound";
import { Card } from "react-bootstrap";
// import { getDataRequestRound } from "./module/getData";
import { getDataRequestRound } from "./module/getDataPaging";
import { getDataStartRound } from "../startround/module/getData";
import ModalPlayerCaddie from "../PlayerCaddieModal";
import Load from "../../../../components/Load";

function FriendRequestTest() {
  const userData = useSelector((state) => state.user.data);
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();
  const [listRoundTest, setListRoundTest] = useState([]);
  const [modalPlayerCaddie, setModalPlayerCaddie] = useState(false);
  const [listUserData, setListUserData] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [noMoreData, setNoMoreData] = useState(false);

  // {Not supported in real time}
  // useEffect(() => {
  //   if (!userData) return;
  //   getDataStartRound(userData, setListRoundStart, setLoading);
  //   getDataRequestRound(userData, setListRoundTest, setLoading).then(
  //     (lastDoc) => setLastVisibleDoc(lastDoc)
  //   );
  // }, [userData]);

  // const handleLoadMore = () => {
  //   if (!lastVisibleDoc || loading || noMoreData) return;

  //   setLoading(true);

  //   getDataRequestRound(
  //     userData,
  //     setListRoundTest,
  //     setLoading,
  //     lastVisibleDoc,
  //     true
  //   )
  //     .then((lastDoc) => {
  //       if (lastDoc) {
  //         setLastVisibleDoc(lastDoc);
  //       } else {
  //         setLastVisibleDoc(null);
  //         setNoMoreData(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error loading more rounds:", error);
  //       setLoading(false);
  //     });
  // };

  // {supported in real time}
  // useEffect(() => {
  //   if (!userData) return;

  //   getDataStartRound(userData, setListRoundStart, setLoading);

  // const unsubscribe = getDataRequestRound(
  //   userData,
  //   setListRoundTest,
  //   setLoading,
  //   setLastVisibleDoc,
  //   null,
  //   false
  // );

  //   return () => unsubscribe(); // Cleanup listener เมื่อ component ถูก unmount
  // }, [userData]);

  // const handleLoadMore = () => {
  //   if (!lastVisibleDoc || loading || noMoreData) return;

  //   setLoading(true);
  //   try {
  //     getDataRequestRound(
  //       userData,
  //       setListRoundTest,
  //       setLoading,
  //       (lastDoc) => {
  //         if (lastDoc) {
  //           setLastVisibleDoc(lastDoc);
  //         } else {
  //           setLastVisibleDoc(null);
  //           setNoMoreData(true);
  //         }
  //       },
  //       lastVisibleDoc,
  //       true
  //     );
  //   } catch (error) {
  //     console.error("Error loading more rounds:", error);
  //     setLoading(false);
  //   }
  // };

  // const handleScroll = debounce(() => {
  //   const isBottom =
  //     window.innerHeight + document.documentElement.scrollTop >=
  //     document.documentElement.scrollHeight - 200;

  //   if (isBottom && !loading && !noMoreData) {
  //     handleLoadMore();
  //   }
  // }, 200);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [loading, noMoreData]);

  useEffect(() => {
    if (!userData) return;

    getDataStartRound(userData, setListRoundStart, setLoading);

    const unsubscribe = getDataRequestRound(
      userData,
      setListRoundTest,
      setLoading,
      setLastVisibleDoc,
      null,
      false
    );

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe(); // ✅ ป้องกัน error: unsubscribe is not a function
      }
    };
  }, [userData]);

  const handleLoadMore = async () => {
    if (!lastVisibleDoc || loading || noMoreData) return;

    setLoading(true);
    setNoMoreData(false); // ✅ กำหนดให้โหลดได้อีกครั้งถ้ามีข้อมูล

    try {
      const newLastDoc = await getDataRequestRound(
        userData,
        setListRoundTest,
        setLoading,
        (lastDoc) => {
          if (lastDoc) {
            setLastVisibleDoc(lastDoc);
          } else {
            setNoMoreData(true); // ✅ ถ้าไม่มีข้อมูลเพิ่ม ให้หยุดโหลด
          }
        },
        lastVisibleDoc,
        true
      );

      if (!newLastDoc) {
        setNoMoreData(true);
      }
    } catch (error) {
      console.error("Error loading more rounds:", error);
      setLoading(false);
    }
  };

  const handleScroll = debounce(() => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 200;

    if (isBottom && !loading && !noMoreData) {
      console.log("Loading more data...");
      handleLoadMore();
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, noMoreData]);

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleJoinClick = (roundId, teeTime) => {
    if (listRoundStart.length > 0 && teeTime == "now") {
      Swal.fire({
        title: "Round In Progress",
        text: "You still have a round in progress. Please finish that round first.",
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    try {
      await denyRound(roundId, userId);
      console.log("Round denied successfully");
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleOpenPlayerCaddieModal = (userData) => {
    setModalPlayerCaddie(true);
    setListUserData(userData);
  };

  const handleClosePlayerCaddieModal = () => setModalPlayerCaddie(false);

  const filteredRoundRequest = listRoundTest.sort(
    (a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)
  );

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body
          className="main-player-stat"
          style={{ padding: "0.5rem 0.5rem" }}
        >
          <>
            {listRoundTest.length > 0 ? (
              listRoundTest.map((round, index) => (
                <div
                  key={index}
                  className="create-round-req-main"
                  style={{ border: "1px solid #e1e1f0 " }}
                >
                  <section className="create-round-req-head">
                    <div className="pointer">
                      <h4 className="fw-600 mb-1">{round.roundName}</h4>
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                      </h6>
                    </div>
                  </section>
                  <section
                    className="create-round-req-body p-1 pointer"
                    style={{
                      backgroundImage: round.coverImage
                        ? `url(${
                            typeof round.coverImage === "string"
                              ? round.coverImage
                              : URL.createObjectURL(round.coverImage)
                          })`
                        : "none",
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data px-0 row"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={70}
                          height={70}
                          className="rounded-circle my-2 d-sm-block d-none"
                        />
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={50}
                          height={50}
                          className="rounded-circle my-2 d-sm-none d-block"
                        />
                      </div>
                      <div className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center">
                        <div>
                          {/* desktop */}
                          <span className="fw-600 fs-5 d-sm-block d-none">
                            {round.courseInfo.courseName}
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">
                            {round.courseInfo.courseName}
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>
                          {/* desktop */}
                          <span className="fw-600 fs-5 d-sm-block d-none">{`${round.courseInfo.location.provinceEng}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-2 col-3 px-sm-20">
                        <div
                          className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor:
                              round.teeTime === "schedule"
                                ? "#E59E49"
                                : "#81D77A",
                          }}
                        >
                          <h5 className="fw-600 text-white text-center">
                            {round.month}
                          </h5>
                          <h5 className="fw-700 text-white text-center">
                            {round.day}
                          </h5>
                          <div className="bg-white text-center w-100">
                            <span
                              className="fw-600 fs-6"
                              style={{
                                color:
                                  round.teeTime === "schedule"
                                    ? "#E59E49"
                                    : "#81D77A",
                              }}
                            >
                              {round.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* Desktop display with 7 user limit */}
                    <div
                      className="d-none d-sm-flex flex-column justify-content-between pointer"
                      style={{ padding: "0.6rem 1rem 0.7rem" }}
                    >
                      <div className="d-flex align-items-center mb-3 ms-2">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "0px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.desktop && (
                          <div
                            className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                            style={{
                              width: 40,
                              height: 40,
                              opacity: 0.6,
                            }}
                          >
                            <h4
                              className="text-center"
                              onClick={() =>
                                handleOpenPlayerCaddieModal(round.userData)
                              }
                            >
                              +{round.userData.length - maxUserList.desktop}
                            </h4>
                          </div>
                        )}
                      </div>

                      <div className="d-flex align-items-center">
                        {round.userData.map(
                          (user, idx) =>
                            user.isCreator && (
                              <div key={idx}>
                                <h6
                                  className="fw-600"
                                  style={{ fontSize: "11px" }}
                                >
                                  Request By {user.firstName} {user.lastName}
                                </h6>
                              </div>
                            )
                        )}
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="p-2 lh-20 w100 bg-current me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          onClick={() =>
                            handleJoinClick(round.roundId, round.teeTime)
                          }
                        >
                          Join
                        </button>
                        <button
                          className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          style={{ background: "#f5f5f5" }}
                          onClick={() =>
                            handleDeny(round.roundId, userData.uid)
                          }
                        >
                          Deny
                        </button>
                      </div>
                    </div>

                    <div
                      className="d-sm-none d-flex flex-column justify-content-between pointer"
                      style={{ padding: "0.3rem 1rem 0.6rem" }}
                    >
                      <div className="d-flex align-items-center mb-3 ms-2">
                        {round.userData
                          .slice(0, maxUserList.mobile)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "0px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.mobile && (
                          <div
                            className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                            style={{
                              width: 40,
                              height: 40,
                            }}
                          >
                            <h4
                              className="text-center"
                              onClick={() =>
                                handleOpenPlayerCaddieModal(round.userData)
                              }
                            >
                              +{round.userData.length - maxUserList.mobile}
                            </h4>
                          </div>
                        )}
                      </div>

                      <div className="d-flex align-items-center mb-3">
                        {round.userData.map(
                          (user, idx) =>
                            user.isCreator && (
                              <div key={idx}>
                                <h6 className="fw-600 fs-7">
                                  Request By {user.firstName} {user.lastName}
                                </h6>
                              </div>
                            )
                        )}
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="p-2 lh-20 w100 bg-current me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          onClick={() =>
                            handleJoinClick(round.roundId, round.teeTime)
                          }
                        >
                          Join
                        </button>
                        <button
                          className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          style={{ background: "#f5f5f5", color: "black" }}
                          onClick={() =>
                            handleDeny(round.roundId, userData.uid)
                          }
                        >
                          Deny
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              ))
            ) : !loading ? (
              <h5 className="text-center p-2">No rounds request available</h5>
            ) : null}
            {loading && (
              <div className="d-flex justify-content-center">
                <Load />
              </div>
            )}
          </>
        </Card.Body>
      </Card>

      <ModalPlayerCaddie
        isOpen={modalPlayerCaddie}
        handleClose={handleClosePlayerCaddieModal}
        listUserData={listUserData}
      />
    </>
  );
}
export default FriendRequestTest;

function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
