import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  startAfter,
  limit,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { deleteRoundForCreator } from "../module/deleteRound";
// import { getDataFinshedRound } from "./module/getData";
import { getDataFinshedRound } from "./module/getDataPaging";

import { Score } from "./module/getScore";
import { sortScore } from "../../../LeaderboardV2/module/tableFunc";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../../components/language/dropdown/textconvert";
import Swal from "sweetalert2";
import Load from "../../../../components/Load";

function RoundFinish() {
  const { t } = useTranslation();
  const [listRound, setListRound] = useState([]);
  const [loading, setLoading] = useState(true);

  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [noMoreData, setNoMoreData] = useState(false);

  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 3 };
  const navigate = useNavigate();
  // const [roundIds, setRoundIds] = useState([]);

  //   useEffect(() => {
  //     if (!userData) return;

  //     getDataFinshedRound(userData, setListRound, setLoading);
  //   }, [userData]);

  useEffect(() => {
    if (!userData) return;

    getDataFinshedRound(userData, setListRound, setLoading).then((lastDoc) =>
      setLastVisibleDoc(lastDoc)
    );
  }, [userData]);

  const handleLoadMore = () => {
    if (!lastVisibleDoc || loading || noMoreData) return;

    setLoading(true);

    getDataFinshedRound(
      userData,
      setListRound,
      setLoading,
      lastVisibleDoc,
      true
    )
      .then((lastDoc) => {
        if (lastDoc) {
          setLastVisibleDoc(lastDoc);
        } else {
          setLastVisibleDoc(null);
          setNoMoreData(true);
        }
      })
      .catch((error) => {
        console.error("Error loading more rounds:", error);
        setLoading(false);
      });
  };

  const handleScroll = debounce(() => {
    const isBottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 200;

    if (isBottom && !loading && !noMoreData) {
      handleLoadMore();
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, noMoreData]);

  const handleDeleteRound = async (roundId) => {
    // try {
    //   await deleteRoundForCreator(roundId);
    // } catch (error) {
    //   console.error("Error deleted round:", error);
    // }
    Swal.fire({
      title: "Delete this round?",
      html: `
            <h5 class="fw-600">Are you sure you want to delete this round?</h5>
          `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteRoundForCreator(roundId);
          console.log("Round denied successfully");
          Swal.fire("Deleted!", "The round has been denied.", "success");
        } catch (error) {
          console.error("Error delete round:", error);
          Swal.fire("Error!", "There was an issue delete the round.", "error");
        }
      } else {
        console.log("Round deleted cancelled");
      }
    });
  };

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  const [selectedHoles, setSelectedHoles] = useState("allRounds"); // 'all' means showing all rounds

  const handleSelection = (e) => {
    setSelectedHoles(e.target.value);
  };

  const filteredRounds = listRound.filter((round) => {
    if (selectedHoles === "18Holes") {
      return round.holes === "18 holes";
    } else if (selectedHoles === "9Holes") {
      return round.holes === "9 holes";
    }
    return true; // Show all rounds when 'All Rounds' is selected
  });

  //   const filteredRoundFinished = filteredRounds.sort(
  //     (a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)
  //   );

  const handleChat = async (channelUrl) => {
    navigate("/chat-room?channelId=" + channelUrl);
  };

  const validRounds = filteredRounds.filter(
    (round) => round.holesCourse !== ""
  );
  const getScore = Score(validRounds);
  const rankedScores = assignRank(getScore);
  // console.log(rankedScores);
  // console.log(filteredRounds);

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body
          className="main-player-stat"
          style={{ padding: "0.5rem 0.5rem" }}
        >
          <>
            <div className="d-flex align-items-center mb-2">
              <select
                className="form-select"
                aria-label="Default select example"
                style={{
                  height: "40px",
                }}
                onChange={handleSelection}
              >
                <option selected value="allRounds">
                  All Rounds
                </option>
                <option value="18Holes">18 Holes Finished</option>
                <option value="9Holes">9 Holes Finished</option>
              </select>

              {/* Icon for filtering */}
              <div
                style={{
                  cursor: "pointer",
                  height: "40px",
                  width: "40px",
                }}
                className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
              >
                <i className="feather-filter font-xss text-grey-500"></i>
              </div>
            </div>

            {filteredRounds.length > 0 ? (
              filteredRounds.map((round, index) => (
                <div
                  key={index}
                  className="create-round-req-main"
                  style={{ border: "1px solid #e1e1f0 " }}
                >
                  <section className="create-round-req-head">
                    <div className="pointer">
                      <h4 className="fw-600 mb-1">{round.roundName}</h4>
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                      </h6>
                    </div>
                    <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                      <ReactSVG
                        src="assets/images/dooNineIconV2/chat-SL.svg"
                        wrapper="svg"
                        style={{ height: 40, width: 40 }}
                        className={clsx("pointer", {
                          "d-none": round.groupChat !== "create",
                        })}
                        onClick={() => handleChat(round.channelUrl)}
                      />
                      <Dropdown className="drodownFriend" drop="down">
                        <Dropdown.Toggle
                          as="span" // Use a span element for the wrapper
                          id="dropdown-basic"
                          style={{ cursor: "pointer", zIndex: 1000 }}
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                            wrapper="svg"
                            className="wizrd-more-btn"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdown}>
                          <Dropdown.Item href={`/score?round=${round.roundId}`}>
                            {t(textConvert("Resume Round"))}
                          </Dropdown.Item>
                          <Dropdown.Item
                            href={`/leader-board?round=${round.roundId}`}
                          >
                            {t(textConvert("Leaderboard"))}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/game-mode" disabled>
                            {t(textConvert("Game mode"))}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/competitor" disabled>
                            {t(textConvert("Competitor"))}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href={`/p-and-c-round?round=${round?.roundId}`}
                            disabled
                          >
                            {t(textConvert("Player and Caddie"))}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleLinkToCourse(round.course)}
                          >
                            {t(textConvert("Course"))}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item disabled>
                            {t(textConvert("Edit Round"))}
                          </Dropdown.Item>
                          <Dropdown.Item disabled>
                            {t(textConvert("Invitation Link"))}
                          </Dropdown.Item>
                          {round.userData.some(
                            (user) =>
                              user.uid === userData.uid &&
                              user.isCreator === true
                          ) ? (
                            <Dropdown.Item
                              onClick={() => handleDeleteRound(round.roundId)}
                            >
                              {t(textConvert("Delete Round"))}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item href="#/leave-round" disabled>
                              {t(textConvert("Leave Round"))}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </section>
                  <section
                    className="create-round-req-body p-1 pointer"
                    //   onClick={() => handleChatandDateClick()}
                    style={{
                      backgroundImage: round.coverImage
                        ? typeof round.coverImage === "string"
                          ? `url(${round.coverImage})`
                          : `url(${URL.createObjectURL(round.coverImage)})`
                        : "none",
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data px-0 row"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div
                        className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={70}
                          height={70}
                          className="rounded-circle my-2 d-sm-block d-none"
                        />
                        {/* mobile */}
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={50}
                          height={50}
                          className="rounded-circle my-2 d-sm-none d-block"
                        />
                      </div>
                      <div
                        className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        <div>
                          {/* desktop */}
                          <span className="fw-600 fs-5 d-sm-block d-none">
                            {round.courseInfo.courseName}
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">
                            {round.courseInfo.courseName}
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>

                          <span className="fw-600 fs-5 d-sm-block d-none">{`${round.courseInfo.location.provinceEng}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-2 col-3 px-sm-20">
                        <div
                          className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                          style={{ backgroundColor: "#212529" }}
                          // onClick={() => handleChatandDateClick()}
                        >
                          <h5 className="fw-600 text-white text-center">
                            {round.month}
                          </h5>
                          <h5 className="fw-700 text-white text-center">
                            {round.day}
                          </h5>
                          <div className="bg-white text-center w-100">
                            <span
                              className="fw-600 fs-6"
                              style={{ color: "#212529" }}
                            >
                              {round.time}
                            </span>
                          </div>
                          {/* {round.teeTime === "finished" ? (
                              <>
                                <h4 className="fw-600 text-white text-center">
                                  {round.month}
                                </h4>
                                <h1 className="fw-700 text-white text-center">
                                  {round.day}
                                </h1>
                                <div className="bg-white text-center w-100">
                                  <span
                                    className="fw-600 fs-4"
                                    style={{ color: "#212529" }}
                                  >
                                    {round.time}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="bg-white text-center w-100">
                                <span
                                  className="fw-600 fs-4"
                                  style={{ color: "#212529" }}
                                >
                                  Start Now
                                </span>
                              </div>
                            )} */}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* desktop display 7 limit */}
                    <div
                      className="d-none d-sm-flex align-items-center justify-content-between pointer"
                      style={{ padding: "0.6rem 1rem 0.7rem" }}
                    >
                      {/* Left side: User profile images */}
                      <Link to={`/leader-board?round=${round.roundId}`}>
                        <div className="d-flex align-items-center">
                          {round.userData
                            .slice(0, maxUserList.desktop)
                            .map((user, idx) => {
                              const userScore =
                                Array.isArray(rankedScores) &&
                                rankedScores.find(
                                  (score) =>
                                    score.roundId === round.roundId &&
                                    ((user.role === "player" &&
                                      score.userId === user.uid) ||
                                      (user.role === "caddie" &&
                                        score.caddieId === user.uid))
                                );

                              return (
                                <React.Fragment key={idx}>
                                  <div
                                    className={`d-flex align-items-center ${
                                      user.role === "player" && idx === 0
                                        ? "me-4"
                                        : ""
                                    }`}
                                  >
                                    {user.uid === userData.uid &&
                                      user.role === "caddie" &&
                                      (userScore?.rank ||
                                        userScore?.sumScore) && ( // ตรวจสอบว่ามี rank หรือ score
                                        <div className="d-flex flex-column align-items-start">
                                          {userScore?.rank && (
                                            <h5 className="fw-600 mb-1">
                                              {userScore.rank}
                                            </h5>
                                          )}
                                          {userScore?.sumScore && (
                                            <h6 className="me-4">
                                              {`+ ${userScore.sumScore} (${round.holes})`}
                                            </h6>
                                          )}
                                        </div>
                                      )}
                                    <img
                                      src={user.profileImage}
                                      alt={
                                        user.role === "player"
                                          ? "player"
                                          : "caddie"
                                      }
                                      width={50}
                                      height={50}
                                      className={`rounded-circle ${
                                        user.role === "player"
                                          ? "player-color-outline"
                                          : "caddie-color-outline"
                                      }`}
                                      style={{
                                        zIndex: maxUserList.desktop - idx,
                                      }}
                                    />
                                    {user.uid === userData.uid &&
                                      user.role === "player" &&
                                      (userScore?.rank ||
                                        userScore?.sumScore) && ( // ตรวจสอบว่ามี rank หรือ score
                                        <div className="d-flex flex-column align-items-start">
                                          {userScore?.rank && (
                                            <h5 className="fw-600 ms-4 mb-1">
                                              {userScore.rank}
                                            </h5>
                                          )}
                                          {userScore?.sumScore && (
                                            <h6 className="ms-4">
                                              {`+ ${userScore.sumScore} (${round.holes})`}
                                            </h6>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          {round.userData.length > maxUserList.desktop ? (
                            <div
                              className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                              style={{
                                width: 50,
                                height: 50,
                                opacity: 0.6,
                              }}
                            >
                              <h5 className="text-center">
                                +{round.userData.length - maxUserList.desktop}{" "}
                              </h5>
                            </div>
                          ) : null}
                        </div>
                      </Link>

                      <Link to={`/review-round?round=${round.roundId}`}>
                        {round.userList
                          ?.filter(
                            (user) =>
                              userData?.uid === user.player?.uid && // Ensure this user has a caddie role
                              (user.isReview === undefined ||
                                user.isReview === false)
                          )
                          ?.map((user) => {
                            const caddieData = round.userData.find(
                              (data) => data.uid === user.caddie?.uid
                            );
                            return (
                              caddieData && (
                                <div key={caddieData.uid}>
                                  <button className="d-flex align-items-center p-1 lh-20 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <img
                                        src={caddieData.profileImage}
                                        alt="caddie"
                                        width={35}
                                        height={35}
                                        className="rounded-circle caddie-color-outline"
                                        style={{
                                          objectFit: "cover",
                                        }}
                                      />
                                      <ReactSVG
                                        src="assets/images/dooNineIconV3/SVG RoundActivity 030924/RoundActivity-Review.svg"
                                        wrapper="svg"
                                        style={{ height: 35, width: 35 }}
                                      />
                                    </div>
                                  </button>
                                </div>
                              )
                            );
                          })}
                      </Link>
                    </div>

                    {/* mobile display 5 limit */}
                    <div
                      className="d-sm-none d-flex align-items-center justify-content-between pointer"
                      style={{ padding: "0.3rem 1rem 0.6rem" }}
                    >
                      {/* Left side: User profile images */}
                      <Link to={`/leader-board?round=${round.roundId}`}>
                        <div className="d-flex align-items-center">
                          {round.userData
                            .slice(0, maxUserList.mobile)
                            .map((user, idx) => {
                              const userScore =
                                Array.isArray(rankedScores) &&
                                rankedScores.find(
                                  (score) =>
                                    score.roundId === round.roundId &&
                                    ((user.role === "player" &&
                                      score.userId === user.uid) ||
                                      (user.role === "caddie" &&
                                        score.caddieId === user.uid))
                                );

                              return (
                                <React.Fragment key={idx}>
                                  <div
                                    className={`d-flex align-items-center ${
                                      user.role === "player" && idx === 0
                                        ? "me-3"
                                        : ""
                                    }`}
                                  >
                                    {user.uid === userData.uid &&
                                      user.role === "caddie" &&
                                      (userScore?.rank ||
                                        userScore?.sumScore) && ( // ตรวจสอบว่ามี rank หรือ score
                                        <div className="d-flex flex-column align-items-start">
                                          {userScore?.rank && (
                                            <h5 className="fw-600 mb-1">
                                              {userScore.rank}
                                            </h5>
                                          )}
                                          {userScore?.sumScore && (
                                            <h6 className="me-4">
                                              {`+ ${userScore.sumScore} (${round.holes})`}
                                            </h6>
                                          )}
                                        </div>
                                      )}
                                    <img
                                      src={user.profileImage}
                                      alt={
                                        user.role === "player"
                                          ? "player"
                                          : "caddie"
                                      }
                                      width={40}
                                      height={40}
                                      className={`rounded-circle ${
                                        user.role === "player"
                                          ? "player-color-outline"
                                          : "caddie-color-outline"
                                      }`}
                                      style={{
                                        zIndex: maxUserList.mobile - idx,
                                      }}
                                    />
                                    {user.uid === userData.uid &&
                                      user.role === "player" &&
                                      (userScore?.rank ||
                                        userScore?.sumScore) && ( // ตรวจสอบว่ามี rank หรือ score
                                        <div className="d-flex flex-column align-items-start">
                                          {userScore?.rank && (
                                            <h5 className="fw-600 ms-2 mb-1">
                                              {userScore.rank}
                                            </h5>
                                          )}
                                          {userScore?.sumScore && (
                                            <h6 className="ms-2">
                                              {`+ ${userScore.sumScore} (${round.holes})`}
                                            </h6>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          {round.userData.length > maxUserList.mobile ? (
                            <div
                              className="d-flex justify-content-center align-items-center flex-wrap bg-light theme-light-bg p-1"
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            >
                              <h4 className="text-center">
                                +{round.userData.length - maxUserList.mobile}{" "}
                              </h4>
                            </div>
                          ) : null}
                        </div>
                      </Link>

                      <Link to={`/review-round?round=${round.roundId}`}>
                        {round.userList
                          ?.filter(
                            (user) =>
                              userData?.uid === user.player?.uid && // Ensure this user has a caddie role
                              (user.isReview === undefined ||
                                user.isReview === false)
                          )
                          ?.map((user) => {
                            const caddieData = round.userData.find(
                              (data) => data.uid === user.caddie?.uid
                            );
                            return (
                              caddieData && (
                                <div key={caddieData.uid}>
                                  <button className="d-flex align-items-center p-1 lh-20 bg-current text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <img
                                        src={caddieData.profileImage}
                                        alt="caddie"
                                        width={35}
                                        height={35}
                                        className="rounded-circle caddie-color-outline"
                                        style={{
                                          objectFit: "cover",
                                        }}
                                      />
                                      <ReactSVG
                                        src="assets/images/dooNineIconV3/SVG RoundActivity 030924/RoundActivity-Review.svg"
                                        wrapper="svg"
                                        style={{ height: 35, width: 35 }}
                                      />
                                    </div>
                                  </button>
                                </div>
                              )
                            );
                          })}
                      </Link>
                    </div>
                  </section>
                </div>
              ))
            ) : !loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <h5>No rounds available.</h5>
              </div>
            ) : null}

            {loading && (
              <div className="d-flex justify-content-center">
                <Load />
              </div>
            )}
          </>
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundFinish;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}

function assignRank(scores) {
  const result = [];

  // แยกข้อมูลตาม roundId
  const groupedByRound = scores.reduce((acc, score) => {
    if (!acc[score.roundId]) {
      acc[score.roundId] = [];
    }
    acc[score.roundId].push(score);
    return acc;
  }, {});

  // จัดการข้อมูลแต่ละ round
  Object.values(groupedByRound).forEach((roundScores) => {
    // ตรวจสอบ gameMode (Stableford หรืออื่นๆ)
    const isStableford = roundScores[0]?.gameMode === "Stableford";
    const sortedScores = sortScore([...roundScores], "sumScore", isStableford);

    // กำหนดอันดับและรูปแบบ (1st, 2nd, 3rd)
    sortedScores.forEach((score, index) => {
      score.rank = `${index + 1}${getRankSuffix(index + 1)}`;
      result.push(score);
    });
  });

  return result;
}

function getRankSuffix(rank) {
  if (rank % 10 === 1 && rank % 100 !== 11) return "st";
  if (rank % 10 === 2 && rank % 100 !== 12) return "nd";
  if (rank % 10 === 3 && rank % 100 !== 13) return "rd";
  return "th";
}

function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
