import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Darkbutton from '../components/Darkbutton';

import clsx from 'clsx';

import { useAuth } from './auth/UserContext';

import axios from 'axios';
import { ReactSVG } from 'react-svg';
import Notification from '../pages/notifications/Notification';
import NoCourseToast from './NoCourseToast';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from './language/dropdown/DropDownLang';
import { textConvert } from './language/dropdown/textconvert';

// const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';
const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

const caddie_icon = "assets/images/dooNineicon/caddie.svg";
const caddie_sl = "assets/images/dooNineIconV2/caddie-Short-Cut-SL.svg";
const caddie_dark_nl = "assets/images/dooNineIconV2/caddie-Short-Cut-NL-DARK.svg";
const caddie_light_nl = "assets/images/dooNineIconV2/caddie-Short-Cut-NL-LIGHT.svg";
const add_icon = "assets/images/dooNineIconV2/create-Activity.svg";
const resume_Activity = "assets/images/dooNineIconV2/resume-Activity.svg";
const fav_Friend = "assets/images/dooNineIconV2/fav-Friend.svg";
const course = "assets/images/dooNineIconV2/golf-Course.svg";
const tour = "assets/images/dooNineIconV2/tournament.svg";
const accountSetting = "assets/images/dooNineIconV2/account-Setting.svg";
const chatNS = "assets/images/dooNineIconV2/chat-NS-LIGHT.svg";
const chatNS_dark = "assets/images/dooNineIconV2/chat-NS-DARK.svg";
const chatSL = "assets/images/dooNineIconV2/chat-SL.svg";
const feedNS = "assets/images/dooNineIconV2/feed-NS-LIGHT.svg";
const feedNS_dark = "assets/images/dooNineIconV2/feed-NS-DARK.svg";
const feedSL = "assets/images/dooNineIconV2/feed-SL.svg";
const golfNS = "assets/images/dooNineIconV2/golf-Activity-NS-LIGHT.svg";
const golfNS_dark = "assets/images/dooNineIconV2/golf-Activity-NS-DARK.svg";
const golfSL = "assets/images/dooNineIconV2/golf-Activity-SL.svg";
const priv = "assets/images/dooNineIconV2/privacy.svg";
const store = "assets/images/dooNineIconV2/store.svg";
const myFriend = "assets/images/dooNineIconV2/my-Friend.svg";
const myPerf = "assets/images/dooNineIconV2/my-Performance.svg";
const myact = "assets/images/dooNineIconV2/my-Activity.svg";
const logout_icon = "assets/images/dooNineIconV2/logout.svg";

const logo = 'assets/images/banner/doonine_revB.png';
const text = 'assets/images/banner/doonine_text_2.png';

const Header = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isNoti, setIsNoti] = useState(false);

    const [unreadMessageCount, setUnreadMEssageCount] = useState(0);

    const location = useLocation();
    const navigate = useNavigate()

    const { logout } = useAuth();

    const userData = useSelector((state) => state.user.data);

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'theme-light');

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = () => setIsActive(!isActive);
    const toggleisNoti = () => setIsNoti(!isNoti);

    const navClass = `${isOpen ? " nav-active" : ""}`;
    const buttonClass = `${isOpen ? " active" : ""}`;
    const searchClass = `${isActive ? " show" : ""}`;
    const notiClass = `${isNoti ? " show" : ""}`;

    const userPath = window.location.pathname === '/userPage' ? true : false;
    const selectedPath = window.location.pathname;

    const handleClickOutside = (event) => {
        if (!event.target.closest('.nav-active') && !event.target.closest('.nav-top')) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // light theme only //
    useEffect(() => {
        localStorage.setItem("theme", "theme-light")
    }, [])
    // light theme only //


    function handleThemeMode(themeMode) {
        setTheme(themeMode)
    }

    useEffect(() => {
        async function fetchData() {

            try {
                const response = await axios.get(`https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${userData.dooNineId}/unread_message_count`, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf8',
                        'Api-Token': '1a3a0f2103a01d56c01cde731a802118945aeeb4'
                    }
                });

                setUnreadMEssageCount(response.data.unread_count)


            } catch (error) {
                console.log(error)
            }

        }
        if (userData) {
            fetchData();
        }
    }, [userData]);

    return (
        <>
            <NoCourseToast />

            <div className="nav-header bg-white shadow-xs border-0">
                <div className="nav-top">
                    {/* mobile */}
                    <Link to="/home">
                        <div className='log-container d-flex align-items-center'>
                            <img src={logo} alt='logo' width={55} />
                            <img className='d-none d-md-block' src={text} alt='logo' width={160} />
                        </div>
                    </Link>
                    <Link to="/my-favorites" className="mob-menu ms-auto me-2">
                        <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-fav-Friend.svg' className='w50' />
                    </Link>

                    <Link to="/myfriend" state={{ action: 'add' }} className="mob-menu me-2">
                        {/* <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-create-activity.svg' className='w50' /> */}
                        <div className='bg-light rounded-circle d-flex justify-content-center align-items-center' style={{ width: '44.43px', height: '44.43px' }}>
                            <i className='bi bi-person-plus-fill' style={{ color: '#000', fontSize: 20 }} />
                        </div>
                    </Link>

                    {/* <Link to="#" className="mob-menu me-2">
                    <ReactSVG src='assets/images/dooNineIconV2/mobile/mobile-Noti.svg' className='w50' />
                </Link> */}
                    <Notification isMobile={true} />

                    <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
                </div>
                {/* mobile */}

                <NavLink
                    to="/home"
                    className={({ isActive }) => `p-2 text-center ms-3 menu-icon center-menu-icon ${isActive ? 'active' : ''}`}
                    id='home-btn'
                    onClick={() => window.scrollTo(0, 0)}
                >
                    {iconTheme(theme, selectedPath, "/home", feedNS, feedNS_dark, feedSL)}
                </NavLink>
                <NavLink
                    to="/caddie"
                    className={({ isActive }) => `p-2 text-center ms-3 menu-icon center-menu-icon ${isActive ? 'active' : ''}`}
                >
                    {iconTheme(theme, selectedPath, "/caddie", caddie_light_nl, caddie_dark_nl, caddie_sl)}
                </NavLink>
                <NavLink
                    to="/round-activity"
                    className={({ isActive }) => `p-2 text-center ms-3 menu-icon center-menu-icon ${isActive ? 'active' : ''}`}
                >
                    {iconTheme(theme, selectedPath, "/round-activity", golfNS, golfNS_dark, golfSL)}
                </NavLink>
                <NavLink
                    to="/chat"
                    className={({ isActive }) => `p-2 text-center ms-3 menu-icon center-menu-icon ${isActive ? 'active' : ''}`}
                    style={{ position: 'relative' }}
                >
                    {iconTheme(theme, selectedPath, "/chat", chatNS, chatNS_dark, chatSL)}

                    {unreadMessageCount > 0 && (
                        <span className="rounded-circle d-flex align-items-center justify-content-center"
                            style={{ position: 'absolute', width: 25, height: 25, right: 1, top: 1, backgroundColor: theme === 'theme-light' ? '#F5F5F5' : '#1A2335' }}
                        >
                            <h5 className='m-0'>{unreadMessageCount}</h5>
                        </span>
                    )}
                </NavLink>
                <NavLink
                    to="/userPage"
                    className={({ isActive }) => `p-2 text-center ms-3 menu-icon center-menu-icon rounded-circle ${isActive ? 'active' : ''}`}
                >
                    <div className='rounded-circle' style={{ backgroundColor: userPath ? '#ff5676' : '', width: '52px', height: '52px' }}>
                        <img
                            src={userData?.profileImage || defaultProfile}
                            alt="user"
                            className='font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500'
                            style={{
                                opacity: userPath ? 0.7 : 1,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                            onError={(e) => {
                                e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                e.target.src = defaultProfile;  // Replace with default image on error
                            }}
                        />

                    </div>
                </NavLink>


                <Link to="/my-favorites" className="p-2 ms-auto text-center ms-3 menu-icon chat-active-btn"><img src={fav_Friend} alt='fav_Friend' className='dooNine-icon-header-right' /></Link>
                {/* <Link to="#" className="p-2 text-center ms-3 menu-icon chat-active-btn"><img src={add_icon} alt='add' className='dooNine-icon-header-right' /></Link> */}
                <Link to="/myfriend" state={{ action: 'add' }} className="p-2 text-center ms-3 menu-icon chat-active-btn">
                    {/* <img src={add_icon} alt='add' className='dooNine-icon-header-right' /> */}
                    <i className='bi bi-person-plus-fill dooNine-icon-header-right' style={{ color: '#EE3C7F', fontSize: 26 }} />
                </Link>
                <Notification />

                {/* <Link to="/chat" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i className="feather-message-square font-xl text-current"></i></Link> */}
                <div className='d-none'>
                    <Darkbutton
                        themeMode={handleThemeMode}
                    />
                </div>
                {/* <Link to="/defaultsettings" className="p-0 ms-3 menu-icon"><img src={userData?.profileImage} alt="user" className="w40 mt--1 rounded-2" /></Link> */}
                <div className={clsx('d-block', {
                    'd-none': !isOpen
                })} style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)', position: 'fixed', top: 0, left: 0 }}>
                </div>
                <nav className={`navigation scroll-bar ${navClass}`}>
                    <div className="container ps-0 pe-0">
                        <div className="nav-content">
                            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                                <div className="nav-caption fw-600 font-xssss text-grey-500 d-flex justify-content-between align-items-center">
                                    <span>{t(textConvert('menu'))}</span>
                                    <div className=' px-3 py-0'>
                                        <LanguageDropdown size={20} />
                                    </div>

                                </div>
                                <ul className="mb-1 top-content">
                                    <li className="logo d-none d-xl-block d-lg-block"></li>
                                    <li><Link to="/my-perf-profile" className="nav-content-bttn open-font"><img src={myPerf} alt='myperf' className='dooNine-icon' /><span>{t('my_performance')}</span></Link></li>
                                    <li><Link to="/course" className="nav-content-bttn open-font"><img src={course} alt='course' className='dooNine-icon' /><span>{t('golf_course')}</span></Link></li>
                                    <li><Link to="/caddie" className="nav-content-bttn open-font"><img src={caddie_icon} alt='caddie' className='dooNine-icon' /><span>{t('caddies')}</span></Link></li>
                                    <li><Link to="/chat" className="nav-content-bttn open-font"><i alt='caddie' className='dooNine-icon bi bi-chat-left-dots-fill text-current text-center' /><span>{t('chat')}</span></Link></li>
                                    <li style={{ pointerEvents: 'none' }}><Link to="" className="nav-content-bttn open-font"><img src={tour} alt='tour' className='dooNine-icon' /><span className='text-muted'>{t('tournament')}</span></Link></li>
                                    <li style={{ pointerEvents: 'none' }}><Link to="" className="nav-content-bttn open-font"><img src={store} alt='store' className='dooNine-icon' /><span className='text-muted'>{t('store')}</span></Link></li>
                                </ul>
                            </div>

                            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                                <div className="nav-caption fw-600 font-xssss text-grey-500"><span>{t(textConvert('more'))}</span></div>
                                <ul className="mb-3">
                                    <li><Link to="/myfriend" className="nav-content-bttn open-font"><img src={myFriend} alt='myfriend' className='dooNine-icon' /><span>{t('my_friends')}</span></Link></li>
                                    <li><Link to="/round-activity" className="nav-content-bttn open-font"><img src={myact} alt='myact' className='dooNine-icon' /><span>{t('my_activity')}</span></Link></li>
                                    <li><Link to="/defaultsettings" className="nav-content-bttn open-font"><img src={accountSetting} alt='accSetting' className='dooNine-icon' /><span>{t('settings')}</span></Link></li>
                                    <li><Link to="/privacy" className="nav-content-bttn open-font"><img src={priv} alt='priv' className='dooNine-icon' /><span>{t('privacy')}</span></Link></li>
                                    {/* <li onClick={() => document.getElementById('theme-toggle').click()}>
                                        <Link to={`${location.pathname}${location.search}`} className="nav-content-bttn open-font overflow-visible">
                                            {theme === 'theme-dark' ? <>
                                                <i className='feather-moon font-xl text-current' style={{ marginRight: '0.8rem' }}></i>
                                                <span>Theme : Dark</span>
                                            </> :
                                                <>
                                                    <i className='feather-sun font-xl text-current' style={{ marginRight: '0.8rem' }}></i>
                                                    <span>Theme : Light</span>
                                                </>
                                            }
                                        </Link>
                                    </li> */}
                                    <li><Link to="#" className="nav-content-bttn open-font" onClick={logout}><img src={logout_icon} alt='logout' className='dooNine-icon' /><span>{t('logout')}</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav >

                <div className={`app-header-search ${searchClass}`}>
                    <form className="search-form">
                        <div className="form-group searchbox mb-0 border-0 p-1">
                            <input type="text" className="form-control border-0" placeholder="Search..." />
                            <i className="input-icon">
                                <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                            </i>
                            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                                <i className="ti-close font-xs" onClick={toggleActive}></i>
                            </span>
                        </div>
                    </form>
                </div>

            </div >
        </>
    );
}

export default Header;

function iconTheme(theme, selectPath, currentPath, light, dark, select) {

    return (
        <>
            {theme === "theme-dark" && (
                <>
                    <img src={select} alt='sl' className={clsx('dooNine-icon-header', { 'd-none': selectPath !== currentPath })} />

                    <img src={dark} alt='ns-dark' className={clsx('dooNine-icon-header', { 'd-none': selectPath === currentPath })} />
                </>
            )}
            {theme === "theme-light" && (
                <>
                    <img src={select} alt='sl' className={clsx('dooNine-icon-header', { 'd-none': selectPath !== currentPath })} />

                    <img src={light} alt='ns' className={clsx('dooNine-icon-header', { 'd-none': selectPath === currentPath })} />
                </>
            )}
        </>
    )
}