import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { en } from './components/language/en';
import { th } from './components/language/th';

import ReactDOM from 'react-dom/client';

import './main.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-photo-view/dist/react-photo-view.css';
// import 'react-image-crop/dist/ReactCrop.css'

import { useAuth, AuthProvider } from './components/auth/UserContext';
import { FriendProvider } from './components/friendsListContext';
import ProtectedRoute from './components/auth/ProtectedRoute';

import store from './components/redux/store';
import { Provider } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserData, updateUser } from './components/redux/feature/user/userSlice';
import { firestore } from './components/firebase/FirebaseConfig';
import { where, query, getDocs, collection } from 'firebase/firestore';
import _ from 'lodash';

import Demo from './demo/Demo';
import Home from './pages/home/Home';
import Badge from './pages/Badge';
import Group from './pages/Group';
import Storie from './pages/Storie';
import Member from './pages/Member';
import Email from './pages/Email';
import Emailopen from './pages/Emailopen';
import Settings from './pages/account/Settings';
import Account from './pages/account/Account';
import Contactinfo from './pages/Contactinfo';
import Socialaccount from './pages/Socialaccount';
import Password from './pages/Password';
import Payment from './pages/Payment';
import Notification from './pages/Notification';
import Helpbox from './pages/Helpbox';
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import Forgot from './pages/Forgot';
import Notfound from './pages/Notfound';
import ShopOne from './pages/ShopOne';
import ShopTwo from './pages/ShopTwo';
import ShopThree from './pages/ShopThree';
import Singleproduct from './pages/Singleproduct';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
// import Chat from './pages/chat/Chat';
import Chat from './pages/chatV2/Chat';
import Live from './pages/Live';
import Job from './pages/Job';
import Event from './pages/Event';
import Hotel from './pages/Hotel';
import Videos from './pages/Videos';
import Comingsoon from './pages/Comingsoon';
import Grouppage from './pages/Grouppage';
import Userpage from './pages/user/Userpage';
import Authorpage from './pages/Authorpage';
import Hotelsingle from './pages/Hotelsingle';
import Analytics from './pages/Analytics';
import RoundActivity from './pages/activity/roundactivity/RoundActivity';
import CreateRoundPage from './pages/activity/component/CreateRoundPage';
import Friend from './pages/friend/Friend';
// import Caddie from './pages/caddie/Caddie';
import Caddie from './pages/caddie/CaddiePaging';
import Tournament from './pages/tournament/Tournament';
import Store from './pages/store/Store';
import MyClubs from './pages/user/myperfprofile/MyClubs';
// import Score from './pages/score/Score';
import ScoreIndex from './pages/scoreV2/ScoreIndex';
// import LeaderBoard from './pages/leaderboard/LeaderBoard';
import LeaderboardIndex from './pages/LeaderboardV2/LeaderboardIndex';
// import MyFriend from './pages/friend/myfriend/MyFriend';
import MyActivity from './pages/activity/myactivity/MyActivity';
import JoinRound from './pages/activity/component/joinRound/JoinRound';
import MyPerfProfile from './pages//user/myperfprofile/MyPerfProfile'
import PerfProfile from './pages/user/profile/PerfProfile';
import MyCourse from './pages/course/Course';
import CourseDetail from './pages/coursedetail/CourseDetail';
import Error404 from './components/404';
import IndividualPost from './pages/home/postview/IndividualPost';

// import ChatRoom from './pages/chat/ChatRoom';
import ChatRoom from './pages/chatV2/ChatRoom';
// import MyFriend from './pages/myfriend/MyFriend';
import MyFriendV2 from './pages/myfriend/MyFriendV2';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import FavoriteFriend from './pages/favoritefriend/FavoriteFriend';
import UserIndex from './pages/user/profile/UserIndex';
import Privacy from './pages/account/Privacy';
import EditCaddieAbout from './pages/user/profile/EditCaddieAbout';
import CaddieReq from './pages/user/profile/CaddieReq';
import BlockList from './pages/account/settingpage/BlockList';
import AddFriend from './pages/addfriend/AddFriend';
import FinishRound from './pages/score/FinishRound';
import ReviewRound from './pages/score/ReviewRound';
import PlayerAndCaddieRound from './pages/scoreV2/PlayerAndCaddieRound';

import CreateGroupChat from './pages/chatV2/CreateGroupChat';
import ChatInvite from './pages/chatV2/ChatInvite';


import LineConnect from './pages/lineconnect/LineConnect';
import LineConnectEnable from './pages/lineconnect/LineConnectEnable';

import { useFriends } from './components/friendsListContext';
import Redirect from './pages/redirect/Redirect';


const Root = () => {

    const currentPath = window.location.pathname;
    const { user, logout } = useAuth();
    const { fetchFriends, setFriendLoaded } = useFriends();

    // const [currentUser, setCurrentUser] = useState(null);
    const dispatch = useDispatch();
    const userStatus = useSelector((state) => state.user.status);
    const userData = useSelector((state) => state.user.data);

    const resources = { en, th };
    const currentLang = localStorage.getItem('i18nextLng') || 'en';

    i18n.use(initReactI18next).init({
        resources,
        lng: currentLang,
        fallbackLng: currentLang,
        interpolation: {
            escapeValue: false,
        },
    });

    useEffect(() => {

        if (user) {
            if (userStatus === 'idle') {
                const userId = user.uid
                dispatch(fetchUserData({ userId, user }));

                setFriendLoaded(false)
            }
            if (userData) {
                fetchFriends();
            }
            const lastActivityTime = parseInt(localStorage.getItem(`lastActivity_${user.uid}`), 10);
            const currentTime = Date.now();
            const inactivityPeriod = userData?.privacy?.logoutTimer * 60 * 1000;

            if (!lastActivityTime) {
                handleActivity();
            } else {
                if (userData?.privacy?.logoutTimer !== 0) {
                    if (currentTime - lastActivityTime > inactivityPeriod) {
                        logout()
                            .then(() => {
                                console.log('User signed out due to inactivity');
                                window.location.href = '/login';
                            })
                            .catch((error) => {
                                console.error('Error signing out:', error);
                            });
                    }
                }
            }
        }

        window.addEventListener('click', handleActivity);
        window.addEventListener('keypress', handleActivity);
        window.addEventListener('touchstart', handleActivity);
        window.addEventListener('scrollend', handleActivity);

        return () => {
            window.removeEventListener('click', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            window.removeEventListener('touchstart', handleActivity);
            window.addEventListener('scrollend', handleActivity);
        };
    }, [user, userData]);

    const handleActivity = _.debounce(() => {
        if (user) {
            localStorage.setItem(`lastActivity_${user.uid}`, Date.now());
        }
    }, 500)

    // remove unsed activity
    useEffect(() => {
        const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

        const cleanUpOldTimestamp = () => {
            const now = Date.now(); // Current timestamp in milliseconds
            const keys = Object.keys(localStorage); // Get all keys in localStorage

            keys.forEach((key) => {
                if (key.startsWith("lastActivity_")) {
                    const value = localStorage.getItem(key);

                    // Ensure the value is a valid epoch timestamp
                    if (!isNaN(value)) {
                        const timestamp = parseInt(value, 10); // Convert value to a number

                        if (now - timestamp > THREE_DAYS_IN_MS) {
                            localStorage.removeItem(key); // Remove item if it's older than 3 days
                            // console.log(`Removed ${key} because it is older than 3 days.`);
                        }
                    }
                }
            });
        };

        cleanUpOldTimestamp(); // Call the cleanup function
    }, []);


    return (
        <Router>
            <Routes>
                {!user ? (
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot" element={<Forgot />} />
                        <Route
                            path={currentPath}
                            element={
                                <Navigate to={currentPath === '/register' ? '/register' : '/login'} />
                            }
                        />
                    </>
                ) : (
                    <>
                        <Route path="/" element={<ProtectedRoute />}>
                            {/*----------------------- use path ------------------------*/}
                            <Route path="home" element={<Home />} />
                            <Route path="defaultsettings" element={< Settings />} />
                            <Route path="accountinformation" element={< Account />} />
                            <Route path="forgot" element={< Forgot />} />
                            <Route path="notfound" element={< Notfound />} />
                            <Route path="chat" element={< Chat />} />
                            <Route path="chat-room" element={< ChatRoom />} />
                            <Route path="create-chat" element={< CreateGroupChat />} />
                            <Route path="invite" element={< ChatInvite />} />

                            <Route path="add-friend" element={< AddFriend />} />
                            <Route path="userpage" element={< Userpage />} />
                            <Route path="friend" element={< Friend />} />
                            <Route path="caddie" element={< Caddie />} />
                            <Route path="tournament" element={< Tournament />} />
                            <Route path="store" element={< Store />} />
                            {/* <Route path="myfriend" element={< MyFriend />} /> */}
                            <Route path="myfriend" element={< MyFriendV2 />} />
                            <Route path="myactivity" element={< MyActivity />} />
                            <Route path="round-activity" element={< RoundActivity />} />
                            <Route path="create-round" element={< CreateRoundPage />} />
                            <Route path="join-round" element={< JoinRound />} />
                            {/* <Route path="myperf" element={< MyPerformance />} /> */}
                            <Route path="my-perf-profile" element={< MyPerfProfile />} />
                            <Route path="perf-profile" element={< PerfProfile />} />
                            <Route path="course" element={<MyCourse />} />
                            <Route path="course-detail" element={<CourseDetail />} />
                            <Route path="my-favorites" element={<FavoriteFriend />} />
                            <Route path="user" element={<UserIndex />} />
                            <Route path="privacy" element={<Privacy />} />
                            <Route path="edit-caddie-about" element={<EditCaddieAbout />} />
                            <Route path="blocklist" element={<BlockList />} />
                            <Route path="caddie-req" element={<CaddieReq />} />
                            <Route path="myclubs" element={<MyClubs />} />
                            {/* <Route path="score" element={<Score />} /> */}
                            <Route path="score" element={<ScoreIndex />} />
                            {/* <Route path="leader-board" element={<LeaderBoard />} /> */}
                            <Route path="leader-board" element={<LeaderboardIndex />} />
                            <Route path="finish-round" element={<FinishRound />} />
                            <Route path="review-round" element={<ReviewRound />} />
                            <Route path="p-and-c-round" element={<PlayerAndCaddieRound />} />
                            <Route path="post" element={<IndividualPost />} />

                            <Route path="login" element={<Navigate to={"/home"} replace />} />
                            <Route path="register" element={<Navigate to={"/home"} replace />} />
                            <Route path="/" element={<Navigate to={"/home"} replace />} />

                            <Route path="*" element={<Error404 />} />

                            <Route path="line-connect" element={<LineConnect />} />
                            <Route path="line-connect-enable" element={<LineConnectEnable />} />
                            <Route path="redirect" element={<Redirect />} />


                            {/* ----------------------- Unused path -----------------------*/}
                            <Route path="badge" element={< Badge />} />
                            <Route path="defaultgroup" element={< Group />} />
                            <Route path="defaultstorie" element={< Storie />} />
                            <Route path="defaultmember" element={< Member />} />
                            <Route path="defaultemailbox" element={< Email />} />
                            <Route path="defaultemailopen" element={< Emailopen />} />
                            <Route path="contactinformation" element={< Contactinfo />} />
                            <Route path="socialaccount" element={< Socialaccount />} />
                            <Route path="password" element={< Password />} />
                            <Route path="payment" element={< Payment />} />
                            <Route path="notification" element={< Notification />} />
                            <Route path="helpbox" element={< Helpbox />} />
                            <Route path="shop1" element={< ShopOne />} />
                            <Route path="shop2" element={< ShopTwo />} />
                            <Route path="shopthree" element={< ShopThree />} />
                            <Route path="singleproduct" element={< Singleproduct />} />
                            <Route path="cart" element={< Cart />} />
                            <Route path="checkout" element={< Checkout />} />
                            <Route path="defaultlive" element={< Live />} />
                            <Route path="job" element={< Job />} />
                            <Route path="defaultevent" element={< Event />} />
                            <Route path="defaulthotel" element={< Hotel />} />
                            <Route path="defaultvideo" element={< Videos />} />
                            <Route path="comingsoon" element={< Comingsoon />} />
                            <Route path="grouppage" element={< Grouppage />} />
                            <Route path="authorpage" element={< Authorpage />} />
                            <Route path="hotelsingle" element={< Hotelsingle />} />
                            <Route path="defaultanalytics" element={< Analytics />} />

                        </Route>
                    </>
                )}
            </Routes>
        </Router>
    );
};


const RootComponent = () => {

    const userData = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const dooNineIdRef = useRef("");
    const { handleCreateUserSendBird } = useAuth();
    const currentLang = localStorage.getItem('i18nextLng') || 'en';

    const warningText = {
        title: currentLang === 'th' ? 'คำเตือน' : 'Warning',
        html: currentLang === 'th' ? `ID ของคุณจะไม่สามารถเปลี่ยนได้ในภายหลัง` : 'Your ID cannot be changed later.',
        okBtn: currentLang === 'th' ? 'ตกลง' : 'OK',
        backBtn: currentLang === 'th' ? 'ย้อนกลับ' : 'Back',
        yourIdIs: currentLang === 'th' ? 'ID ของคุณคือ' : 'Your ID is'
    }
    const warningText2 = {
        title: currentLang === 'th' ? 'กรอก Doonine ID' : 'Enter your Doonine ID',
        submitBtn: currentLang === 'th' ? 'ยืนยัน' : 'Submit',
        skipBtn: currentLang === 'th' ? 'ข้าม' : 'Skip',
        enterId: currentLang === 'th' ? 'กรุณากรอก Doonine ID' : 'Please enter your Doonine ID.',
        sixChar: currentLang === 'th' ? 'ID ต้องมีอย่างน้อย 6 ตัวอักษร' : 'ID must be at least 6 characters long.',
        spaceChar: currentLang === 'th' ? 'ID ต้องไม่มีเว้นวรรค' : 'ID cannot contain spaces.',
        taken: currentLang === 'th' ? 'มีผู้ใช้ ID นี้แล้ว' : 'This ID is already taken.',
    }


    useEffect(() => {

        const dooNineId = `${userData?.firstName.replace(/\s+/g, '')}_${userData?.uid.slice(0, 2)}${userData?.uid.slice(3, 5)}`
        const nick_name = `${userData?.firstName} ${userData?.lastName}`
        dooNineIdRef.current = dooNineId;

        const createAccesstokenSendBird = async (dooNineId) => {
            Swal.fire({
                title: warningText.title,
                html: `<span class="text-danger">${warningText.html}</span> <br>${warningText.yourIdIs} <strong>${dooNineId}</strong>`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ee3d7f",
                confirmButtonText: warningText.okBtn,
                cancelButtonText: warningText.backBtn
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    })
                    const sendBirdRes = await handleCreateUserSendBird(userData.profileImage, dooNineId, nick_name);
                    if (sendBirdRes.success) {
                        dispatch(updateUser(userData.uid, {
                            sendBirdAccessToken: sendBirdRes.data.access_token || "",
                            dooNineId: dooNineId
                        }));
                        Swal.fire({
                            title: "Saved",
                            icon: "success",
                            confirmButtonColor: '#ee3c7f'
                        });
                    } else {
                        console.error('Failed to create sendbird user:', sendBirdRes.error.message);
                        Swal.fire({
                            title: "Something Wrong",
                            text: "Sendbird error code " + sendBirdRes.error.code,
                            icon: "error"
                        }).then(() => {
                            handleDoNineId();
                        });
                    }
                } else if (result.isDismissed) {
                    handleDoNineId();
                }
            });
        }

        async function handleDoNineId() {

            if (userData?.sendBirdAccessToken === "") {

                const { value: dooNineIdVal, isDismissed } = await Swal.fire({
                    input: "text",
                    inputLabel: warningText2.title,
                    inputValue: dooNineIdRef.current,
                    showCancelButton: true,
                    confirmButtonColor: "#ee3d7f",
                    confirmButtonText: warningText2.submitBtn,
                    cancelButtonText: warningText2.skipBtn,
                    inputValidator: async (value) => {
                        if (!value) {
                            return warningText2.enterId;
                        } else if (value.length < 6) {
                            return warningText2.sixChar;
                        } else if (/\s/.test(value)) {
                            return warningText2.spaceChar;
                        } else {
                            const q = query(
                                collection(firestore, "users"),
                                where("dooNineId", "==", value),
                                where("uid", '!=', userData.uid)
                            );
                            const querySnapshot = await getDocs(q);
                            if (!querySnapshot.empty) {
                                return warningText2.taken;
                            }
                            return null;
                        }
                    }
                });

                if (isDismissed) {
                    await createAccesstokenSendBird(dooNineId);
                } else if (dooNineIdVal) {
                    dooNineIdRef.current = dooNineIdVal;
                    await createAccesstokenSendBird(dooNineIdVal);
                } else {
                    console.warn('Please enter Doonine ID');
                }
            }
        }
        if (userData) {
            handleDoNineId()
        }
    }, [userData])


    return (
        <SendbirdProvider
            appId={'B72515CE-E0DA-417F-AF02-4BF3D07B3D50'}
            userId={userData?.dooNineId}
            accessToken={userData?.sendBirdAccessToken}
        >
            <Root />
        </SendbirdProvider>
    );
};


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Provider store={store}>
        <AuthProvider>
            <FriendProvider>
                <RootComponent />
            </FriendProvider>
        </AuthProvider>
    </Provider>
);
