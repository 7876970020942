import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

// export const getDataIsPlayer = async (userData) => {
//   if (!userData) {
//     throw new Error("userData is required");
//   }

//   try {
//     // Step 1: Fetch friend IDs
//     const userQuery = query(
//       collection(firestore, "friends"),
//       where("userId", "==", userData.uid),
//       where("status", "==", "active")
//     );

//     const userQuery2 = query(
//       collection(firestore, "friends"),
//       where("friendId", "==", userData.uid),
//       where("status", "==", "active")
//     );

//     const [userSnapshot, friendSnapshot] = await Promise.all([
//       getDocs(userQuery),
//       getDocs(userQuery2),
//     ]);

//     const friendIds = [
//       ...userSnapshot.docs.map((doc) => doc.data().friendId),
//       ...friendSnapshot.docs.map((doc) => doc.data().userId),
//     ];

//     const uniqueFriendIds = [...new Set(friendIds)];

//     // Step 2: Fetch users from the "users" collection where uid is in friendIds and role === "player"
//     if (uniqueFriendIds.length === 0) return [];

//     const playerQuery = query(
//       collection(firestore, "users"),
//       where("uid", "in", uniqueFriendIds),
//       where("role", "==", "player")
//     );

//     const playerSnapshot = await getDocs(playerQuery);

//     // Step 3: Extract and return the filtered UIDs
//     const playerIds = playerSnapshot.docs.map((doc) => doc.data().uid);
//     if (playerIds.length === 0) return [];

//     const rounds = await getRounds(playerIds, userData);

//     return rounds;
//   } catch (error) {
//     console.error("Error fetching players:", error);
//     throw error;
//   }
// };

// export const getDataIsCaddie = async (userData) => {
//   if (!userData) {
//     throw new Error("userData is required");
//   }

//   try {
//     const caddieQuery = query(
//       collection(firestore, "caddieRequests"),
//       where("caddieId", "==", userData.uid),
//       where("status", "==", "approved")
//     );

//     const caddieSnapshot = await getDocs(caddieQuery);

//     // Step 3: Extract and return the filtered UIDs
//     const coureseId = caddieSnapshot.docs.map((doc) => doc.data().courseId);
//     if (coureseId.length === 0) return [];

//     const rounds = await getRoundsIsCaddie(coureseId, userData);

//     return rounds;
//   } catch (error) {
//     console.error("Error fetching friends:", error);
//     throw error;
//   }
// };

// const getRounds = async (playerIds, userData) => {
//   if (!playerIds || playerIds.length === 0) return [];

//   try {
//     const roundQuery = query(
//       collection(firestore, "round"),
//       where("creator", "in", playerIds)
//     );

//     const roundSnapshot = await getDocs(roundQuery);

//     // ใช้ Promise.all เพื่อรอผลจาก async function
//     const filteredRounds = await Promise.all(
//       roundSnapshot.docs.map(async (doc) => {
//         const roundData = doc.data();

//         if (userData.role === "player") {
//           const availableSlot = roundData.userList.some(
//             (slot) => slot.player?.uid !== userData?.uid && slot.player === null
//           );

//           if (!availableSlot) return null; // ไม่มีช่องว่างให้ Player
//         }

//         return roundData; // คืนค่าที่กรองแล้ว
//       })
//     );

//     // กรองข้อมูลที่เป็น null และที่มีสถานะเป็น deleted
//     const finalRounds = filteredRounds.filter(
//       (round) => round !== null && round.status !== "deleted"
//     );

//     const checkFinishedRound = await checkPlayingRounds(finalRounds);
//     const roundsWithDetails = await addCourseAndHolesData(checkFinishedRound);
//     const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
//     return roundIsPlaying;
//   } catch (error) {
//     console.error("Error fetching rounds:", error);
//     throw error;
//   }
// };

// const getRoundsIsCaddie = async (courseId, userData) => {
//   console.log(courseId);
//   if (!courseId || courseId.length === 0) return [];

//   try {
//     const roundQuery = query(
//       collection(firestore, "round"),
//       where("course", "in", courseId)
//     );

//     const roundSnapshot = await getDocs(roundQuery);

//     // ใช้ Promise.all เพื่อรอผลจาก async function ใน map
//     const filteredRounds = await Promise.all(
//       roundSnapshot.docs.map(async (doc) => {
//         const roundData = doc.data();

//         // ถ้า caddieReq == "dis" ไม่ต้องแสดง round นี้
//         if (roundData.caddieReq === "dis") return null;

//         // เงื่อนไขสำหรับ role caddie
//         if (userData.role === "caddie") {
//           const availableSlot = roundData.userList.some(
//             (slot) => slot.caddie?.uid !== userData?.uid && slot.caddie === null
//           );

//           if (!availableSlot) return null; // ไม่มีช่องว่างให้ Caddie

//           // ถ้า caddieReq == "fav" ให้เช็ค creator กับ favoriteFriends
//           if (roundData.caddieReq === "fav") {
//             const q = query(
//               collection(firestore, "favoriteFriends"),
//               where("caddieId", "==", userData.uid),
//               where("userId", "==", roundData.creator)
//             );

//             const querySnapshot = await getDocs(q);

//             let isFavorite = false;

//             querySnapshot.forEach((doc1) => {
//               if (doc1.data().status === "active") {
//                 isFavorite = true;
//               }
//             });

//             // ถ้าไม่ใช่ favorite (isFavorite == false) ให้ return null
//             if (!isFavorite) return null;
//           }
//         }

//         return roundData; // คืนค่ารอบที่ผ่านเงื่อนไขทั้งหมด
//       })
//     );

//     // กรองข้อมูลที่เป็น null และสถานะเป็น deleted
//     const finalRounds = filteredRounds.filter(
//       (round) => round !== null && round.status !== "deleted"
//     );

//     console.log(finalRounds);

//     // ขั้นตอนเพิ่มเติม
//     const checkFinishedRound = await checkPlayingRounds(finalRounds);
//     const roundsWithDetails = await addCourseAndHolesData(checkFinishedRound);
//     const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
//     return roundIsPlaying;
//   } catch (error) {
//     console.error("Error fetching rounds:", error);
//     throw error;
//   }
// };

export const getDataIsPlayer = async (userData, setListRound) => {
  if (!userData) throw new Error("userData is required");

  try {
    const userQuery = query(
      collection(firestore, "friends"),
      where("userId", "==", userData.uid),
      where("status", "==", "active")
    );

    const userQuery2 = query(
      collection(firestore, "friends"),
      where("friendId", "==", userData.uid),
      where("status", "==", "active")
    );

    const [userSnapshot, friendSnapshot] = await Promise.all([
      getDocs(userQuery),
      getDocs(userQuery2),
    ]);

    const friendIds = [
      ...userSnapshot.docs.map((doc) => doc.data().friendId),
      ...friendSnapshot.docs.map((doc) => doc.data().userId),
    ];

    const uniqueFriendIds = [...new Set(friendIds)];
    if (uniqueFriendIds.length === 0) {
      setListRound([]); // No friends found
      return;
    }

    const playerQuery = query(
      collection(firestore, "users"),
      where("uid", "in", uniqueFriendIds),
      where("role", "==", "player")
    );

    const playerSnapshot = await getDocs(playerQuery);
    const playerIds = playerSnapshot.docs.map((doc) => doc.data().uid);
    if (playerIds.length === 0) {
      setListRound([]); // No players found
      return;
    }

    const roundQuery = query(
      collection(firestore, "round"),
      where("creator", "in", playerIds)
    );

    onSnapshot(roundQuery, async (roundSnapshot) => {
      const filteredRounds = await Promise.all(
        roundSnapshot.docs.map(async (doc) => {
          const roundData = doc.data();

          // ตรวจสอบว่ามี slot ที่ player === null
          const hasEmptySlot = roundData.userList.some(
            (slot) => slot.player === null
          );

          // ตรวจสอบว่าไม่มี slot ที่ player.uid === userData.uid
          const isUserInRound = roundData.userList.some(
            (slot) => slot.player?.uid === userData.uid
          );

          // ถ้าไม่มีช่องว่างหรือมีตัวเองอยู่ในรอบนี้ ให้ข้ามรอบนี้ไป
          if (!hasEmptySlot || isUserInRound) return null;

          if (roundData.status === "deleted") return null;

          return roundData;
        })
      );

      const validRounds = filteredRounds.filter((round) => round !== null);
      const checkFinishedRound = await checkPlayingRounds(validRounds);
      const roundsWithDetails = await addCourseAndHolesData(checkFinishedRound);
      const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
      const roundsRecent = await filterRecentRounds(roundIsPlaying);

      setListRound(roundsRecent);
    });
  } catch (error) {
    console.error("Error fetching players:", error);
    throw error;
  }
};

export const getDataIsCaddie = async (userData, setListRound) => {
  if (!userData) throw new Error("userData is required");

  try {
    const caddieQuery = query(
      collection(firestore, "caddieRequests"),
      where("caddieId", "==", userData.uid),
      where("status", "!=", "removed")
    );

    const caddieSnapshot = await getDocs(caddieQuery);
    const courseIds = caddieSnapshot.docs.map((doc) => doc.data().courseId);
    if (courseIds.length === 0) {
      setListRound([]); // No courses found
      return;
    }

    const roundQuery = query(
      collection(firestore, "round"),
      where("course", "in", courseIds)
    );

    onSnapshot(roundQuery, async (roundSnapshot) => {
      const filteredRounds = await Promise.all(
        roundSnapshot.docs.map(async (doc) => {
          const roundData = doc.data();

          if (roundData.caddieReq === "disable") return null;

          // const availableSlot = roundData.userList.some(
          //   (slot) => slot.caddie?.uid !== userData?.uid && slot.caddie === null
          // );
          // if (!availableSlot) return null;

          // ตรวจสอบว่ามี slot ที่ player === null
          const hasEmptySlot = roundData.userList.some(
            (slot) => slot.caddie === null
          );

          // ตรวจสอบว่าไม่มี slot ที่ player.uid === userData.uid
          const isUserInRound = roundData.userList.some(
            (slot) => slot.caddie?.uid === userData.uid
          );

          // ถ้าไม่มีช่องว่างหรือมีตัวเองอยู่ในรอบนี้ ให้ข้ามรอบนี้ไป
          if (!hasEmptySlot || isUserInRound) return null;

          if (roundData.caddieReq === "favorite") {
            const favQuery = query(
              collection(firestore, "favoriteFriends"),
              where("caddieId", "==", userData.uid),
              where("userId", "==", roundData.creator)
            );

            const favSnapshot = await getDocs(favQuery);
            const isFavorite = favSnapshot.docs.some(
              (doc) => doc.data().status === "active"
            );
            if (!isFavorite) return null;
          }

          if (roundData.status === "deleted") return null;
          return roundData;
        })
      );

      const validRounds = filteredRounds.filter((round) => round !== null);
      const checkFinishedRound = await checkPlayingRounds(validRounds);
      const roundsWithDetails = await addCourseAndHolesData(checkFinishedRound);
      const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
      const roundsRecent = await filterRecentRounds(roundIsPlaying);

      setListRound(roundsRecent);
    });
  } catch (error) {
    console.error("Error fetching caddie rounds:", error);
    throw error;
  }
};

const checkPlayingRounds = async (filteredRounds) => {
  const promises = filteredRounds.map(async (round) => {
    try {
      // if (!round.creator || !round.roundId) {
      //   console.warn(`Skipping round due to missing data:`, round);
      //   return null;
      // }

      const caddieQuery = query(
        collection(firestore, "scoreCards"),
        where("caddieId", "==", round.creator),
        where("roundId", "==", round.roundId)
      );

      const playerQuery = query(
        collection(firestore, "scoreCards"),
        where("userId", "==", round.creator),
        where("roundId", "==", round.roundId)
      );

      const [caddieSnapshot, playerSnapshot] = await Promise.all([
        getDocs(caddieQuery),
        getDocs(playerQuery),
      ]);

      // ถ้าไม่มีข้อมูลในทั้งสอง query ให้คืนค่ารอบเดิม
      if (caddieSnapshot.empty && playerSnapshot.empty) {
        return round;
      }

      // ตรวจสอบว่า status เป็น "playing" หรือไม่
      const isPlaying = [...caddieSnapshot.docs, ...playerSnapshot.docs].some(
        (doc) => {
          const scoreData = doc.data();
          return scoreData.status === "playing";
        }
      );

      return isPlaying ? round : null;
    } catch (error) {
      console.error(`Error checking round ${round.roundId}:`, error);
      return null;
    }
  });

  const results = await Promise.all(promises);
  return results.filter((round) => round !== null);
};

const fetchUserDetails = async (checkFinishedRound) => {
  const promises = checkFinishedRound.map(async (round) => {
    const uids = [];

    round.userList.forEach((slot) => {
      if (slot?.player?.uid) uids.push(slot.player.uid);
      if (slot?.caddie?.uid) uids.push(slot.caddie.uid);
    });

    const uniqueUids = [...new Set(uids)];

    const userDataPromises = uniqueUids.map(async (uid) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();

        const isCreator = round.userList.some(
          (slot) => slot.player?.uid === user.uid && slot.player?.isCreator
        );

        return { ...user, isCreator: isCreator || false };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};

function filterRecentRounds(rounds) {
  // เวลาปัจจุบันในหน่วยวินาที
  const now = Math.floor(Date.now() / 1000);

  // กรองข้อมูลที่ scheduleTime ยังไม่เกิน 24 ชั่วโมง
  return rounds.filter(round => {
      const scheduleTime = round.scheduleTime;
      // เช็คว่าเวลาปัจจุบัน - scheduleTime น้อยกว่า 24 ชั่วโมง (86400 วินาที)
      return now - scheduleTime <= 86400;
  });
}

// import {
//     collection,
//     query,
//     where,
//     getDocs,
//     getDoc,
//     doc,
//   } from "firebase/firestore";
//   import { firestore } from "../../../../../components/firebase/FirebaseConfig";
//   import { epochToDateTime } from "../../../component/wizard/module/covertTime";

//   export const getDataIsPlayer = async (userData) => {
//     if (!userData) {
//       throw new Error("userData is required");
//     }

//     try {
//       // Step 1: Fetch friend IDs
//       const userQuery = query(
//         collection(firestore, "friends"),
//         where("userId", "==", userData.uid),
//         where("status", "==", "active")
//       );

//       const userQuery2 = query(
//         collection(firestore, "friends"),
//         where("friendId", "==", userData.uid),
//         where("status", "==", "active")
//       );

//       const [userSnapshot, friendSnapshot] = await Promise.all([
//         getDocs(userQuery),
//         getDocs(userQuery2),
//       ]);

//       const friendIds = [
//         ...userSnapshot.docs.map((doc) => doc.data().friendId),
//         ...friendSnapshot.docs.map((doc) => doc.data().userId),
//       ];

//       const uniqueFriendIds = [...new Set(friendIds)]; // Get unique IDs

//       // Step 2: Fetch users from the "users" collection where uid is in friendIds and role === "player"
//       if (uniqueFriendIds.length === 0) return []; // No friends found

//       const playerQuery = query(
//         collection(firestore, "users"),
//         where("uid", "in", uniqueFriendIds),
//         where("role", "==", "player")
//       );

//       const playerSnapshot = await getDocs(playerQuery);

//       // Step 3: Extract and return the filtered UIDs
//       const playerIds = playerSnapshot.docs.map((doc) => doc.data().uid); // Use `doc.id` to get the document ID (UID)
//       if (playerIds.length === 0) return []; // No players found

//       const rounds = await getRounds(playerIds, userData);

//       return rounds;
//     } catch (error) {
//       console.error("Error fetching players:", error);
//       throw error;
//     }
//   };

//   export const getDataIsCaddie = async (userData) => {
//     if (!userData) {
//       throw new Error("userData is required");
//     }

//     try {
//       const userQuery = query(
//         collection(firestore, "friends"),
//         where("userId", "==", userData.uid),
//         where("status", "==", "active")
//       );

//       const userQuery2 = query(
//         collection(firestore, "friends"),
//         where("friendId", "==", userData.uid),
//         where("status", "==", "active")
//       );

//       const [userSnapshot, friendSnapshot] = await Promise.all([
//         getDocs(userQuery),
//         getDocs(userQuery2),
//       ]);

//       const friendIds = [
//         ...userSnapshot.docs.map((doc) => doc.data().friendId),
//         ...friendSnapshot.docs.map((doc) => doc.data().userId),
//       ];

//       // Return unique friend IDs
//       return [...new Set(friendIds)];
//     } catch (error) {
//       console.error("Error fetching friends:", error);
//       throw error;
//     }
//   };

//   const getRounds = async (playerIds, userData) => {
//     if (!playerIds || playerIds.length === 0) return []; // Check if playerIds is empty

//     try {
//       const roundQuery = query(
//         collection(firestore, "round"),
//         where("creator", "in", playerIds)
//       );

//       const roundSnapshot = await getDocs(roundQuery);

//       const filteredRounds = await Promise.all(
//         roundSnapshot.docs.map(async (docSnap) => {
//           const roundData = docSnap.data();

//           // Filter based on user role and available slots
//           if (userData?.role === "player") {
//             const availableSlot = roundData.userList.some(
//               (slot) => slot.player?.uid !== userData?.uid && slot.player === null
//             );

//             // Skip the round if no available slot
//             if (!availableSlot) return null;
//           }

//           // Retrieve holesCourse if it exists
//           let holesCourse = "";
//           if (roundData.holesCourse) {
//             const courseHolesRef = doc(
//               firestore,
//               "courseHoles",
//               roundData.holesCourse
//             ); // Ensure correct doc() usage
//             const courseHolesSnapshot = await getDoc(courseHolesRef); // Correct usage of getDoc()
//             holesCourse = courseHolesSnapshot.exists()
//               ? courseHolesSnapshot.data().courseHoleName
//               : "";
//           }

//           // Retrieve course information
//           const courseSnapshot = await getDocs(
//             query(
//               collection(firestore, "courses"),
//               where("id", "==", roundData.course)
//             )
//           );
//           const courseData = courseSnapshot.docs.map((docSnap) =>
//             docSnap.data()
//           )[0]; // Assuming there's only one course with this id

//           // Combine all data
//           const { time, day, month } = epochToDateTime(roundData.scheduleTime);

//           return {
//             ...roundData,
//             holesCourse,
//             courseData,
//             time,
//             day,
//             month,
//           };
//         })
//       );

//       // Filter out null rounds
//       const validRounds = filteredRounds.filter((round) => round !== null);

//       return validRounds;
//     } catch (error) {
//       console.error("Error fetching rounds:", error);
//       throw error;
//     }
//   };
