import React, { useState, useEffect } from "react";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { firestore, storage } from "../../components/firebase/FirebaseConfig";
import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";

import { useAuth } from "../../components/auth/UserContext";

import clsx from "clsx";
import ImageCropper from "../../components/ImageCropper";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../components/redux/feature/user/userSlice";
import LanguageDropdown from "../../components/language/dropdown/DropDownLang";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";

const logo = "assets/images/banner/doonine_revB.png";
const text = "assets/images/banner/doonine_text_2.png";

const Register = () => {
  const { t } = useTranslation();
  const [profileImage, setProfileImage] = useState(null);
  const [defaultProfileImage, setDefaultProfileImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742"
  );
  const [loading, setLoading] = useState(false);
  const [passError, setPassError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileImageChanged, setProfileImageChanged] = useState(null);
  const [croppedModal, setCroppedModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const dispatch = useDispatch();

  const { emailRegister, handleCreateUserSendBird } = useAuth();

  const showCoppedModal = () => setCroppedModal(true);
  const hideCroppedModal = () => setCroppedModal(false);

  const handleCroppedImage = (file, url) => {
    setCroppedImage(url);
    setProfileImage(file);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const {
      fname: { value: firstName = "" } = {},
      lname: { value: lastName = "" } = {},
      phone: { value: phone_number = "" } = {},
      email: { value: email = "" } = {},
      role: { value: role = "" } = {},
      dbirth: { value: birth = "" } = {},
      gender: { value: gender = "" } = {},
      password: { value: password = "" } = {},
      "re-password": { value: re_password = "" } = {},
    } = e.target.elements;


    const birthDate = new Date(birth);
    const day = birthDate.getDate();
    const month = birthDate.getMonth() + 1;
    const year = birthDate.getFullYear();

    if (password !== re_password) {
      setPassError(true);
      return;
    }

    setPassError(false);
    setLoading(true);

    try {
      const user = await emailRegister(email, password);
      if (!user.uid) {
        setErrorMessage(user);
        setLoading(false);
        return;
      }
      setErrorMessage("");

      let profileImageUrl = defaultProfileImage;
      if (profileImage) {
        profileImageUrl = await uploadImageToFirebase(profileImage, user.uid);
      }

      const dooNineId = `${firstName.replace(/\s+/g, "")}_${user.uid.slice(
        0,
        2
      )}${user.uid.slice(3, 5)}`;

      const registerData = {
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        birthDate: {
          day: day || "",
          month: month || "",
          year: year || "",
        },
        gender: gender || "",
        mobile: phone_number || "",
        uid: user?.uid || "",
        profileImage: profileImageUrl || "",
        role: role || "",
        dooNineId: dooNineId || "",
        sendBirdAccessToken: "",
        created: serverTimestamp(),
        privacy: {
          dooNineIdSearch: true,
          emailSearch: false,
          friendsPost: true,
          caddiePost: true,
          playerPost: true,
          defaultAudience: "public",
        },
      };


      if (registerData.role == "caddie") {
        registerData.isStar = false;
        registerData.isPro = false;
      }

      const querySnapshot = await getDocs(
        query(collection(firestore, "users"), where("email", "==", email))
      );
      if (querySnapshot.empty) {
        const usersCollection = collection(firestore, "users");
        const docRef = await addDoc(usersCollection, registerData);

        try {
          const userRef = doc(firestore, "users", docRef.id);
          await updateDoc(userRef, {
            id: docRef.id,
          });
        } catch (error) {
          console.log("Error updating document: ", error);
        }

        window.location.href = "/home";
        console.log("Document written successfully");
      } else {
        console.log("You already have an account");
      }
    } catch (error) {
      console.error("Register Failed", error);
    } finally {
      setLoading(false);
    }
  }

  const handleImageChange = (e) => {
    handleImage(e);
    // Reset the input value to allow selecting the same file again
    e.target.value = null;
  };

  function handleImage(e) {
    const file = e.target.files[0];
    if (file) {
      // console.log(file);
      setProfileImageChanged(URL.createObjectURL(file));
      // setProfileImage(file);
      showCoppedModal();
    }
  }

  const uploadImageToFirebase = async (file, name) => {
    try {
      const storageRef = ref(storage, `profile_image/${name}.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } catch (error) {
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error("Failed to upload image:", error);
      throw error;
    }
  };

  useEffect(() => {
    dispatch(clearUserData());
  }, []);

  return (
    <>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0 d-md-none d-block" style={{ backdropFilter: 'blur(2px)' }}>
          <div className="nav-top w-100 d-flex justify-content-between">
            <div className='log-container d-flex align-items-center'>
              <img src={logo} alt='logo' width={55} />
              <img src={text} alt='logo' width={130} />
            </div>
            {/* <button className="nav-menu me-0 ms-auto"></button> */}

            <LanguageDropdown />
            {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
          </div>
        </div>

        <div className="nav-header bg-transparent shadow-none border-0 d-none d-md-block">
          <div className="nav-top w-100 d-flex justify-content-between">
            <div className='log-container d-flex align-items-center p-2'>
              <img src={logo} alt='logo' width={55} />
              <img src={text} alt='logo' width={180} />
            </div>
            {/* <button className="nav-menu me-0 ms-auto"></button> */}

            <LanguageDropdown />
            {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
          </div>
        </div>
        <div className="row" style={{ height: '100dvh', overflowY: 'scroll' }}>
          <div className="col-xl-7 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{ backgroundImage: `url('assets/images/login/login_resized.jpg')` }}>
          </div>
          <div className="col-xl-5 vh-md-100-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4 mt-3 text-center">
                  {t(textConvert('Create your account'))}
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="image-profile-container mb-3 text-center">
                    <div className="image-profile text-center">
                      <img
                        src={croppedImage || defaultProfileImage}
                        className="border border-2 p-1"
                        alt="profile"
                        style={{ objectFit: "cover", borderRadius: 20 }}
                        width="160px"
                        height="160px"
                      />
                      <input
                        className="d-none"
                        id="upload-img"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                    <i
                      className="feather-camera rounded-circle border border-2 p-2 bg-light shadow-lg"
                      onClick={() =>
                        document.getElementById("upload-img").click()
                      }
                      style={{ fontSize: 25 }}
                    ></i>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-6">
                      <label htmlFor="fname">{t(textConvert('Firstname'))}<span className="text-danger fs-5">*</span></label>
                      <input
                        type="text"
                        className="form-control text-grey-900 font-xsss fw-600"
                        id="fname"
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-6">
                      <label htmlFor="lname">{t(textConvert('Lastname'))}<span className="text-danger fs-5">*</span></label>
                      <input
                        type="text"
                        className="form-control text-grey-900 font-xsss fw-600"
                        id="lname"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 d-none">
                    <label htmlFor="phone">{t(textConvert('Phone'))}</label>
                    <input
                      type="tel"
                      className="form-control text-grey-900 font-xsss fw-600"
                      id="phone"
                    />
                  </div>
                  <div className="row">
                    <div className="form-group mb-3">
                      <label htmlFor="role">{t(textConvert('Role'))}<span className="text-danger fs-5">*</span></label>
                      <select
                        name="role"
                        id="role"
                        className="form-select"
                        required
                      >
                        <option value="">{t(textConvert('no select'))}</option>
                        <option value="player">{t(textConvert('Player'))}</option>
                        <option value="caddie">{t(textConvert('Caddie'))}</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-sm-6 d-none">
                      <label htmlFor="gender">{t(textConvert('Gender'))}</label>
                      <select name="gender" id="gender" className="form-select">
                        <option value="">{t(textConvert('no select'))}</option>
                        <option value="male">{t(textConvert('Male'))}</option>
                        <option value="female">{t(textConvert('Female'))}</option>
                        <option value="other">{t(textConvert('Prefer Not to Say'))}</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group mb-3 d-none">
                    <label htmlFor="dbirth">{t(textConvert('Birth'))}</label>
                    <input
                      type="date"
                      className="form-control text-grey-900 font-xsss fw-600"
                      id="dbirth"
                      style={{ lineHeight: "normal" }}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="email">{t(textConvert('Email'))}<span className="text-danger fs-5">*</span></label>
                    <input
                      type="text"
                      className="form-control text-grey-900 font-xsss fw-600"
                      id="email"
                      required
                    />
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-6">
                      <label htmlFor="password">{t(textConvert('Password'))}<span className="text-danger fs-5">*</span></label>
                      <input
                        type="password"
                        className="form-control text-grey-900 font-xsss fw-600"
                        id="password"
                        required
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-6">
                      <label htmlFor="re-password">{t(textConvert('Re-Password'))}<span className="text-danger fs-5">*</span></label>
                      <input
                        type="password"
                        className="form-control text-grey-900 font-xsss fw-600"
                        id="re-password"
                        required
                      />
                    </div>
                    <span
                      className={clsx("text-danger", {
                        "d-none": !passError,
                      })}
                    >
                      {t(textConvert("Password not matched Try again"))}
                    </span>
                  </div>
                  <span className="text-danger">{errorMessage}</span>
                  <button
                    className="btn player-color text-white w-100 p-2 fs-3 d-flex align-items-center justify-content-center"
                    id="login-button"
                    style={{ minHeight: "3rem" }}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                      >
                        {" "}
                      </div>
                    ) : (
                      t(textConvert("Register"))
                    )}
                  </button>
                </form>
                <div className="col-sm-12 p-0 text-left">
                  <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                    {t(textConvert("Already have account"))}{" "}
                    <a href="/login" className="fw-700 ms-1">
                      {t(textConvert("Login"))}
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageCropper
        show={croppedModal}
        hide={hideCroppedModal}
        profileImage={profileImageChanged}
        croppedImage={handleCroppedImage}
      />
    </>
  );
};

export default Register;
