import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { ChatTo } from './chat/Chat';
import { useFriends } from './friendsListContext';
import LoadCircle from './LoadCircle';
import { useTranslation } from 'react-i18next';
import { textConvert } from './language/dropdown/textconvert';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

const Rightchat = () => {
    const { t } = useTranslation();
    const { friendLists, friendLoaded } = useFriends();

    const [dimensions, setDimensions] = useState({ width: 800, height: 182 });
    const navigate = useNavigate();

    const userData = useSelector((state) => state.user.data);

    const updateDimensions = () => {
        if (window.innerWidth < 500) {
            setDimensions({ width: 450, height: 102 });
        } else {
            const update_width = window.innerWidth - 100;
            const update_height = Math.round(update_width / 4.4);
            setDimensions({ width: update_width, height: update_height });
        }
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const handleFriendClick = async (dooNineId) => {
        const result = await ChatTo(userData.dooNineId, dooNineId);
        if (result) {
            navigate("/chat-room?channelId=" + result[0].channel_url, { replace: true });
        }
    }

    return (
        <>
            <style>
                {`
                    .friend-list-ul :hover {
                        background-color: rgba(199, 199, 199, 0.185);
                    }
                `}
            </style>

            <div id="main-content-wrap" className={`right-chat nav-wrap mt-2 right-scroll-bar ${dimensions.width > 1500 ? "active-sidebar" : " "}`}>
                <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl right-scroll-bar" style={{

                    maxHeight: `85vh`,
                }}>

                    <div className="section full position-relative feed-body">
                        <h4 className="p-3 m-0 font-xsssss text-grey-500 text-uppercase fw-700 ls-3">{t(textConvert('CONTACTS'))}</h4>
                        <hr className='text-muted m-0' />
                        {friendLoaded ?
                            <>
                                {friendLists.length > 0 ? (<>
                                    <ul className="list-group list-group-flush mb-3 friend-list-ul">
                                        {friendLists.map((friend, index) => (
                                            <li key={index} className="list-group-item no-icon pt-2 pb-2 border-0 d-flex align-items-center pointer friend-item"
                                                onClick={() => handleFriendClick(friend.dooNineId)}
                                            >
                                                <figure className="avatar float-left mb-0 me-2 bg-transparent">
                                                    <img src={friend.imageUrl} alt="avater" className="rounded-circle" style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                                        onError={(e) => e.target.src = defaultProfile}
                                                    />
                                                </figure>
                                                <h3 className="fw-700 mb-0 mt-0 bg-transparent">
                                                    <span className="bg-transparent font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{friend.name}</span>
                                                </h3>
                                                <span className={`${friend.status} ms-auto btn-round-xss`}></span>
                                            </li>

                                        ))}
                                    </ul>
                                </>) : (<>
                                    <div className='text-muted text-center fs-6 my-3'>You have no friend</div>
                                </>)}
                            </>
                            :
                            <LoadCircle variant='dark' size='sm' className='p-3 text-center' />
                        }
                    </div>
                </div>

                {/* <div className={`modal-popup-chat ${menuClass}`}>
                <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
                    <div className="modal-popup-header w-100 border-bottom">
                        <div className="card p-3 d-block border-0 d-block">
                            <figure className="avatar mb-0 float-left me-2">
                                <img src="assets/images/user-12.png" alt="avater" className="w35 me-1" />
                            </figure>
                            <h5 className="fw-700 text-primary font-xssss mt-1 mb-1">Hendrix Stamp</h5>
                            <h4 className="text-grey-500 font-xsssss mt-0 mb-0"><span className="d-inline-block bg-success btn-round-xss m-0"></span> Available</h4>
                            <div className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer" onClick={toggleOpen}><i className="ti-close text-grey-900 mt-2 d-inline-block"></i></div>
                        </div>
                    </div>
                    <div className="modal-popup-body w-100 p-3 h-auto">
                        <div className="message"><div className="message-content font-xssss lh-24 fw-500">Hi, how can I help you?</div></div>
                        <div className="date-break font-xsssss lh-24 fw-500 text-grey-500 mt-2 mb-2">Mon 10:20am</div>
                        <div className="message self text-right mt-2"><div className="message-content font-xssss lh-24 fw-500">I want those files for you. I want you to send 1 PDF and 1 image file.</div></div>
                        <div className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 bg-grey rounded-xl float-right" data-title=".dot-typing"><div className="stage"><div className="dot-typing"></div></div></div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="modal-popup-footer w-100 border-top">
                        <div className="card p-3 d-block border-0 d-block">
                            <div className="form-group icon-right-input style1-input mb-0"><input type="text" placeholder="Start typing.." className="form-control rounded-xl bg-greylight border-0 font-xssss fw-500 ps-3" /><i className="feather-send text-grey-500 font-md"></i></div>
                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        </>

    );
}

export default Rightchat;