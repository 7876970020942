import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  onSnapshot,
  limit,
  startAfter,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

// export const getData = async (
//   userData,
//   setCaddieListTest,
//   setListUserBolcked,
//   setOriginalCaddieList,
//   setUserLabels,
//   triggerGetLabel,
//   setLoading,
//   lastVisibleDoc = null
// ) => {
//   if (!userData) return;
//   setLoading(true);

//   // ดึง labels ของผู้ใช้
//   const qLabels = query(
//     collection(firestore, "labels"),
//     where("userId", "==", userData.uid)
//   );
//   const labelsSnapshot = await getDocs(qLabels);
//   const userLabelsData = labelsSnapshot.docs.map((doc) => ({
//     docId: doc.id,
//     ...doc.data(),
//   }));
//   setUserLabels(userLabelsData);

//   // ดึงข้อมูล blocked users
//   const qBlocked = query(
//     collection(firestore, "blockedUsers"),
//     where("userId", "==", userData.uid)
//   );
//   const blockedSnapshot = await getDocs(qBlocked);
//   const listUserBlocked = blockedSnapshot.docs.map(
//     (doc) => doc.data().blockedUserId
//   );
//   setListUserBolcked(listUserBlocked);

//   // ⭐ ดึงข้อมูล caddies โดยวนซ้ำจนกว่าจะได้ครบ 15 รายการ ⭐
//   let caddieList = [];
//   let courseIds = new Set();
//   let newLastVisibleDoc = lastVisibleDoc;

//   while (caddieList.length < 15) {
//     let caddieQuery = query(
//       collection(firestore, "users"),
//       where("role", "==", "caddie"),
//       orderBy("isStar", "desc"),
//       limit(15) // ดึงทีละ 15 เพื่อให้เพียงพอ
//     );

//     if (newLastVisibleDoc) {
//       caddieQuery = query(caddieQuery, startAfter(newLastVisibleDoc));
//     }

//     const caddieSnapshot = await getDocs(caddieQuery);
//     const caddieDocs = caddieSnapshot.docs;

//     if (caddieDocs.length === 0) break; // ถ้าไม่มีข้อมูลเพิ่มให้หยุด

//     // ดึงข้อมูล caddie requests
//     const caddieRequestsPromises = caddieDocs.map(async (docUser) => {
//       const userData = docUser.data();
//       if (!userData.firstName.includes("ทดสอบระบบ") && userData.uid) {
//         const qCaddieRequests = query(
//           collection(firestore, "caddieRequests"),
//           where("caddieId", "==", userData.uid),
//           where("status", "!=", "removed")
//         );

//         const caddieRequestSnapshot = await getDocs(qCaddieRequests);
//         if (!caddieRequestSnapshot.empty) {
//           const docCaddieRequest = caddieRequestSnapshot.docs[0];
//           const courseId = docCaddieRequest.data().courseId;
//           const caddieNumber = docCaddieRequest.data().caddieNumber || null;
//           courseIds.add(courseId);

//           caddieList.push({
//             idDocUser: docUser.id,
//             ...userData,
//             courseId,
//             caddieNumber,
//             imageUrl: userData.profileImage || "user.png",
//             name: `${userData.firstName} ${userData.lastName}`,
//           });
//         }
//       }
//     });

//     await Promise.all(caddieRequestsPromises);

//     newLastVisibleDoc = caddieDocs[caddieDocs.length - 1];

//     // ถ้า Firestore มีข้อมูลไม่พอให้หยุด loop
//     if (caddieDocs.length < 15) break;
//   }

//   // ดึงข้อมูล courses
//   const courseDocs = await Promise.all(
//     [...courseIds].map(async (courseId) => {
//       const docRef = doc(firestore, "courses", courseId);
//       const docCourse = await getDoc(docRef);
//       return docCourse.exists() ? { courseId, ...docCourse.data() } : null;
//     })
//   );

//   const courseMap = new Map();
//   courseDocs.forEach((course) => {
//     if (course) {
//       courseMap.set(course.courseId, course);
//     }
//   });

//   // ผูกข้อมูล course กับ caddies
//   caddieList.forEach((caddie) => {
//     const courseData = courseMap.get(caddie.courseId);
//     if (courseData) {
//       caddie.courseName = courseData.courseName;
//       caddie.location = courseData.location;
//     }
//   });

//   // ดึง labels ของ caddies
//   await Promise.all(
//     caddieList.map(async (caddie) => {
//       const qCaddieLabels = query(
//         collection(firestore, "labeledUsers"),
//         where("friendId", "==", caddie.uid),
//         where("userId", "==", userData.uid)
//       );
//       const caddieLabelsSnapshot = await getDocs(qCaddieLabels);
//       let labels = [];

//       caddieLabelsSnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.labels) {
//           labels = data.labels
//             .map((labelId) => {
//               const item = userLabelsData.find(
//                 (item) => item.docId === labelId
//               );
//               return item?.label;
//             })
//             .filter(Boolean);
//         }
//       });

//       caddie.labels = labels;
//     })
//   );

//   setLoading(false);
//   setCaddieListTest((prevList) => [...prevList, ...caddieList]);
//   setOriginalCaddieList((prevList) => [...prevList, ...caddieList]);

//   // Return last visible document เพื่อใช้ในการดึงข้อมูลหน้าใหม่
//   return newLastVisibleDoc;
// };


export const getData = async (
  userData,
  setCaddieListTest,
  setListUserBolcked,
  setOriginalCaddieList,
  setUserLabels,
  triggerGetLabel,
  setLoading,
  lastVisibleDoc = null,
  allCaddieList = [] // Collect all caddies across recursive calls
) => {
  if (!userData) return;
  setLoading(true);

  // ✅ ดึง labels ของผู้ใช้
  const qLabels = query(
    collection(firestore, "labels"),
    where("userId", "==", userData.uid)
  );
  const labelsSnapshot = await getDocs(qLabels);
  const userLabelsData = labelsSnapshot.docs.map((doc) => ({
    docId: doc.id,
    ...doc.data(),
  }));
  setUserLabels(userLabelsData);

  // ✅ ดึงข้อมูล blocked users
  const qBlocked = query(
    collection(firestore, "blockedUsers"),
    where("userId", "==", userData.uid)
  );
  const blockedSnapshot = await getDocs(qBlocked);
  const listUserBlocked = blockedSnapshot.docs.map(
    (doc) => doc.data().blockedUserId
  );
  setListUserBolcked(listUserBlocked);

  // ✅ ดึงข้อมูล caddies และวน loop จนกว่าจะได้ครบ 15 รายการ
  let courseIds = new Set();
  let newLastVisibleDoc = lastVisibleDoc;

  // Recursive function to fetch caddies in batches of 15
  const fetchCaddies = async () => {
    let caddieQuery = query(
      collection(firestore, "users"),
      where("role", "==", "caddie"),
      orderBy("isStar", "desc"),
      limit(15)
    );

    if (newLastVisibleDoc) {
      caddieQuery = query(caddieQuery, startAfter(newLastVisibleDoc));
    }

    const caddieSnapshot = await getDocs(caddieQuery);
    const caddieDocs = caddieSnapshot.docs;

    // console.log("📌 Caddies Fetched:", caddieDocs.length);

    if (caddieDocs.length === 0) return; // No more data to fetch

    // ✅ ดึงข้อมูล caddie requests
    const caddieRequestsPromises = caddieDocs.map(async (docUser) => {
      const userData = docUser.data();
      if (!userData.firstName.includes("ทดสอบระบบ") && userData.uid) {
        const qCaddieRequests = query(
          collection(firestore, "caddieRequests"),
          where("caddieId", "==", userData.uid),
          where("status", "!=", "removed")
        );

        const caddieRequestSnapshot = await getDocs(qCaddieRequests);
        if (!caddieRequestSnapshot.empty) {
          const docCaddieRequest = caddieRequestSnapshot.docs[0];
          const courseId = docCaddieRequest.data().courseId;
          const caddieNumber = docCaddieRequest.data().caddieNumber || null;
          courseIds.add(courseId);

          allCaddieList.push({
            idDocUser: docUser.id,
            ...userData,
            courseId,
            caddieNumber,
            imageUrl: userData.profileImage || "user.png",
            name: `${userData.firstName} ${userData.lastName}`,
          });
        }
      }
    });

    await Promise.all(caddieRequestsPromises);

    newLastVisibleDoc = caddieDocs[caddieDocs.length - 1];

    // console.log("✅ Caddies After Filtering:", allCaddieList.length);

    // Check if we have fetched enough caddies (at least 15 in total)
    if (allCaddieList.length < 15) {
      await fetchCaddies(); // Recursive call to fetch more data
    }
  };

  // Start fetching the caddies data recursively
  await fetchCaddies();

  // ✅ ดึงข้อมูล courses
  const courseDocs = await Promise.all(
    [...courseIds].map(async (courseId) => {
      const docRef = doc(firestore, "courses", courseId);
      const docCourse = await getDoc(docRef);
      return docCourse.exists() ? { courseId, ...docCourse.data() } : null;
    })
  );

  const courseMap = new Map();
  courseDocs.forEach((course) => {
    if (course) {
      courseMap.set(course.courseId, course);
    }
  });

  // ✅ ผูกข้อมูล course กับ caddies
  allCaddieList.forEach((caddie) => {
    const courseData = courseMap.get(caddie.courseId);
    if (courseData) {
      caddie.courseName = courseData.courseName;
      caddie.location = courseData.location;
    }
  });

  // ✅ ดึง labels ของ caddies
  await Promise.all(
    allCaddieList.map(async (caddie) => {
      const qCaddieLabels = query(
        collection(firestore, "labeledUsers"),
        where("friendId", "==", caddie.uid),
        where("userId", "==", userData.uid)
      );
      const caddieLabelsSnapshot = await getDocs(qCaddieLabels);
      let labels = [];

      caddieLabelsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.labels) {
          labels = data.labels
            .map((labelId) => {
              const item = userLabelsData.find(
                (item) => item.docId === labelId
              );
              return item?.label;
            })
            .filter(Boolean);
        }
      });

      caddie.labels = labels;
    })
  );

  setLoading(false);
  setCaddieListTest((prevList) => [...prevList, ...allCaddieList]);
  setOriginalCaddieList((prevList) => [...prevList, ...allCaddieList]);

  // console.log("🚀 Final Caddie List Length:", allCaddieList.length);

  return newLastVisibleDoc; // ✅ Return lastVisibleDoc สำหรับดึงข้อมูลถัดไป
};

