import React, { useEffect, useState } from "react";

import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import {
  notificationBubbleStyle,
} from "./StyleComponent";

import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";
import clsx from "clsx";
import moment from "moment";

const menu = "assets/images/message/Chat-Menu.svg";
const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

const ChannelList = ({ channel, dropDownRef, handleDropdownRef }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const [isPlayer, setIsPlayer] = useState(false);
  const [friendData, setFriendData] = useState(null);

  const isDirectChannel = channel?.memberCount === 2 && channel?.isDistinct;
  const data = isDirectChannel
    ? channel?.members.find((member) => member.userId !== userData?.dooNineId)
    : null;

  const data2 = !isDirectChannel
    ? channel?.members.find((member) => member.userId === userData?.dooNineId)
    : null;

  const lastSeenAt = data ? data.lastSeenAt : null;

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n])


  useEffect(() => {
    const fetchData = async () => {
      if (data?.userId) {
        try {
          const q = query(
            collection(firestore, "users"),
            where("dooNineId", "==", data.userId)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setFriendData(doc.data());
            if (doc.data().role === "player") {
              setIsPlayer(true);
            }
          });
          if (querySnapshot.empty) {
            setIsPlayer(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [data]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await axios.get(
  //       `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channel.url}/metadata`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json; charset=utf8",
  //           "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
  //         },
  //       }
  //     );
  //     console.log(response?.data?.chatType);
  //   }

  //   if (channel) {
  //     fetchData();
  //   }
  // }, [channel]);

  const handleClickChannel = (channel) => {
    navigate("/chat-room?channelId=" + channel);
  };

  const handleDeleteChat = (channelUrl) => {
    Swal.fire({
      title: "Do you want to delete this chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelUrl}`,

            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
              },
            }
          );

          Swal.fire({
            title: "Delete Channel Success ",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/chat");
          });
        } catch (error) {
          console.error("Error leaving channel:", error);
        }
      }
    });
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleLeaveChat = async (channelUrl) => {
    Swal.fire({
      title: "Do you want to leave this chat?",
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response2 = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelUrl}/messages`,
          {
            message_type: "MESG",
            user_id: userData.dooNineId,
            message: `${userData.firstName} ${userData.lastName} leave this group chat.`,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );

        const response = await axios.put(
          "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/" +
          channelUrl +
          "/leave",
          {
            user_ids: [userData.dooNineId],
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Leave group success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            // navigate("/chat");
          });
        } else {
          console.log(response);
          Swal.fire({
            icon: "error",
            title: "Leave group failed",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  return (
    <>
      {isDirectChannel || data2?.state === "joined" ? (
        <div
          key={channel.url}
          onClick={() => handleClickChannel(channel.url)}
          className="d-flex justify-content-between align-items-center gap-2 p-2 border-bottom"
        >
          <div className="d-inline-flex align-items-center text-truncate p-1">
            <div style={{ position: "relative" }}>
              {isDirectChannel ? (
                <img
                  src={data?.plainProfileUrl}
                  alt="avatar"
                  className="rounded-circle"
                  style={{
                    width: 50,
                    height: 50,
                    outline: isPlayer
                      ? "3px solid #4a74e5"
                      : "3px solid #ee3d7f",
                  }}
                />
              ) : (
                <img
                  src={channel?.coverUrl}
                  alt="chat cover"
                  className="rounded-circle"
                  style={{
                    width: 50,
                    height: 50,
                    outline: "3px solid #81d77a",
                  }}
                />
              )}
              {isDirectChannel && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    width: "16px",
                    height: "16px",
                    backgroundColor: lastSeenAt === "0" ? "#3ce44d" : "#B0B5BD",
                    borderRadius: "50%",
                    border: "2px solid white",
                    // zIndex: 1,
                  }}
                ></div>
              )}
            </div>
            <div className="d-flex flex-column ms-2 gap-1 text-truncate">
              <h5 className="fw-600 mb-1 text-truncate">
                {isDirectChannel
                  ? data?.nickname
                  : `${channel?.name} (${channel?.memberCount})`}
              </h5>
              <h6 className={clsx("fw-500 text-truncate", { 'text-muted': channel.unreadMessageCount < 1 })}>
                {channel.lastMessage?.message}
              </h6>
            </div>
          </div>

          <div className="d-inline-flex align-items-center">
            {/* <span className="text-nowrap"> {formatDate(channel.lastMessage?.createdAt)}</span> */}
            <span className="text-nowrap">{formatMessageTime(channel.lastMessage?.createdAt || channel?.joinedAt, i18n)}</span>
            <span style={notificationBubbleStyle(channel.unreadMessageCount)}>
              {channel.unreadMessageCount}
            </span>

            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                show={dropDownRef === channel.url ? true : false}
                onToggle={() => handleDropdownRef(dropDownRef === channel.url ? null : channel.url)}
              >
                <Dropdown.Toggle className="bg-transparent p-0 border-0">
                  <ReactSVG
                    src={menu}
                    style={{ width: "35px", cursor: "pointer" }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {isDirectChannel && (
                    <Dropdown.Item
                      onClick={() => handleProfileClick(friendData?.uid)}
                    >
                      {t(textConvert('Profile'))}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => handleLeaveChat(channel.url)}
                  >
                    {t(textConvert('Leave Chat'))}
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                  onClick={() => handleDeleteChat(channel.url)}
                >
                  Delete Chat
                </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const formatDate = (createdAt) => {
  if (createdAt == undefined) {
    return " ";
  }

  const date = new Date(createdAt);
  const now = new Date();

  // คำนวณความแตกต่างของเวลาในวัน
  const timeDifference = now - date;
  const oneDay = 24 * 60 * 60 * 1000; // หนึ่งวันในมิลลิวินาที

  if (timeDifference < oneDay && date.getDate() === now.getDate()) {
    // ถ้าเป็นวันนี้ แสดงเวลา
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${period}`;
  } else if (
    timeDifference < 2 * oneDay &&
    now.getDate() - date.getDate() === 1
  ) {
    // ถ้าเป็นเมื่อวาน แสดง "Yesterday"
    return "Yesterday";
  } else {
    // แสดงวันที่ในรูปแบบเดือน/วัน
    const options = { month: "short", day: "numeric" }; // เดือนแบบสั้น เช่น "Aug"
    return date.toLocaleDateString("en-US", options);
  }
};

function formatMessageTime(timestamp, i18n) {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  // convert to mill if sec
  if (timestamp <= 1e12) {
    timestamp = timestamp * 1000;
  }
  if (moment(timestamp).isSame(today, 'day')) {
    return moment(timestamp).format('LT');

    // } else if (moment(timestamp).isSame(yesterday, 'day')) {
    //   return moment(timestamp).calendar();

  } else {
    return moment(timestamp).format(i18n.language === 'th' ? 'DD MMM' : 'MMM DD');
  }
}
export default ChannelList;


